<template>
  <div class="bg-white" v-if="liveList.length > 0">
    <div class="xz-b p-b-20">
      <ClassTitle
        class="p-t-40 p-b-20"
        title="近期直播课"
        englishTitle="Recent live broadcast"
        imgSrc="http://webxiaozhu.xzjy365.com/images/%E8%BF%91%E6%9C%9F%E7%9B%B4%E6%92%ADneiJ90"
      />
      <LiveItem :liveList="liveList" />
    </div>
  </div>
</template>

<script>
import LiveItem from "@/components/public/Live/liveItem";
export default {
  components: {
    LiveItem,
  },
  created(){
    this.getLiveList()
  },
  data() {
    return {
      liveList:[],
    };
  },
  methods:{
    getLiveList(){
      let data = {
        uid : !this.$f.uid('0') ? null : this.$f.uid()
      }
      this.Api.Login.getLiveList(data).then(res => {
        this.liveList = this.liveList.concat(res.data)
      })
    }
  }
};
</script>

<style scoped>
</style>