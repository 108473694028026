<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="payVisible"
      width="50%"
      :before-close="handleClose"
      :close-on-click-modal="false"
      top="1vh"
    >
      <el-row>
        <TitleText text="商品订单信息" />
        <OrderInfo :tableData="orderList" />
        <TitleText text="选择优惠券" />
        <Coupon :couponList="couponList" />
        <TitleText text="支付方式" />
        <Mode ref="mode" @editPayRadio="editPayRadio" />
        <div v-show="payType">
          <TitleText text="扫码支付(支付过程中如遇到问题请联系伴学老师)" />
          <WxPay ref="wxpay" v-show="payRadio == '1'" :icon="wxPayPic" />
          <AliPay ref="alipay" v-show="payRadio == '2'" :icon="aliPayPic" />
          <!-- <el-button type="success" size="mini">支付完成没有反应请点击</el-button> -->
        </div>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button type="text" class="xz-color-black"
          >商品总金额:¥{{ order.coursePrice }}</el-button
        >
        <el-button type="text" class="xz-color-black"
          >优惠金额:¥{{ order.coupon }}</el-button
        >
        <el-button type="text" class="xz-color-black"
          >实付:<span class="xz-color-red f-s-28"
            >¥{{ order.discountPrice }}</span
          ></el-button
        >
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="payment">{{
          payType ? "支付完成没有反应请点击" : "确定"
        }}</el-button>
      </span>
    </el-dialog>
    <Notice ref="notice" :cid="cid" />
  </div>
</template>

<script>
import OrderInfo from "@/components/pay/orderInfo";
import TitleText from "@/components/pay/titleText";
import Coupon from "@/components/pay/coupon";
import Mode from "@/components/pay/mode";
import Notice from "@/components/learningCenter/class/item/notice";

export default {
  components: {
    OrderInfo,
    TitleText,
    Coupon,
    Mode,
    Notice
  },
  props: {
    title: {
      type: String,
      default: "订单详情",
    },
    detial: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      payVisible: false,
      orderList: [
        {
          orderNumber: "", // 订单编号
          courseTitle: "", // 名称
          coursePrice: "", // 价格
          discountPrice: "", // 优惠后价格
        },
      ],
      couponList: [], // 存放优惠券列表
      order: {},
      payRadio: "1",
      wxUrl: "", // 用来存储微信的支付地址
      aliUrl: "", // 用来存储支付宝的支付地址
      payType: false, // 控制扫码支付的显示隐藏
      wxPayPic: require("@/assets/img/pay/wxPayPic.jpeg"),
      aliPayPic: require("@/assets/img/pay/ali.jpeg"),
      cid:''
    };
  },
  methods: {
    // 修改父组件选中的支付方式
    editPayRadio(val) {
      this.payRadio = val;
      if (this.payType) this.payment("c");
    },
    // 获取优惠券列表
    getCoupon() {
      let data = {
        uid: this.$f.uid(),
        cid: this.detial.cid,
      };
      this.Api.Pay.getCoupon(data).then((res) => {
        this.couponList = res.data;
      });
    },
    // 获取订单
    getOrder() {
      let data = {
        uid: this.$f.uid(),
        cid: this.detial.cid,
        actTypeId: this.detial.actTypeId,
      };
      this.Api.Pay.generateOrder(data).then((res) => {
        this.order = res.data;
        this.orderList[0].orderNumber = res.data.orderNumber;
        this.orderList[0].courseTitle = res.data.courseTitle == undefined ? res.data.ctitle : res.data.courseTitle;
        this.orderList[0].coursePrice = res.data.coursePrice;
        this.orderList[0].discountPrice = res.data.discountPrice;
      });
    },
    // 付款
    payment(c) {
      if (this.payType && c == undefined) {
        this.payLook();
        return false;
      }
      let type = this.$refs.mode.radio;
      if (type == "1" && this.wxUrl != "") {
        return;
      } else if (type == "2" && this.aliUrl != "") {
        return;
      }
      let data = {
        aid : this.order.aid,
        uid: this.$f.uid(),
        cid: this.detial.cid,
        orderNumber: this.order.orderNumber,
        type,
        userCourseId: this.order.userCourseId,
      };
      this.Api.Pay.Pay(data).then((res) => {
        this.payType = true;
        if (this.$refs.mode.radio == "1") {
          this.wxUrl = res.data.qRcode;
          this.$refs.wxpay.wxPayUrl = res.data.qRcode;
        } else {
          this.aliUrl = res.data.qRcode;
          this.$refs.alipay.alpayUrl = res.data.qRcode;
        }
      });
    },
    handleClose() {
      if (this.payType) {
        this.$confirm("确定要取消本次支付嘛", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.payVisible = false;
          })
          .catch(() => {});
      } else {
        this.payVisible = false;
      }
    },
    payLook() {
      if(!this.payVisible) return false
      let data = {
        orderNumber: this.order.orderNumber,
      };
      this.Api.Pay.payResult(data).then((res) => {
        if (res.status == 200) {
          let payInfo = res.data;
          if (payInfo.state == 1) {
            setTimeout(() => {
              this.payLook();
            }, 2000);
            return false;
          }
          let payTitle = payInfo.state == 2 ? "支付成功" : "支付失败";
          let payType = payInfo.state == 2 ? "success" : "warning";
          if (payInfo.state == 2) this.$parent.paySuc();
          this.$confirm(payTitle, "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: payType,
          })
            .then(() => {
              if (payInfo.state == 2) {
                let data = {
                  cid : this.order.cid,
                  uid : this.$f.uid()
                }
                this.cid = data.cid
                this.Api.Agreement.userIfSignAgreement(data).then(res => {
                  if(res.retuCode == 5028 || res.retuCode == 5025){  // 用户未签署协议
                    this.$refs.notice.dialogVisible = true
                  }  else {
                    let { href } = this.$router.resolve({
                      path: "/player",
                      query: { cid: this.order.cid },
                    });
                    window.open(href, "_blank");
                  }
                }).catch(() => {
                  let { href } = this.$router.resolve({
                    path: "/player",
                    query: { cid: this.order.cid },
                  });
                  window.open(href, "_blank");
                })
                this.$message.success("去详情");
              } else {
                this.payLook();
              }
            })
            .catch(() => {
              this.payVisible = false;
            });
        }
      });
    },
  },
  watch: {
    payVisible: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val) {
          this.getOrder();
          this.getCoupon();
        }
      },
    },
    payType: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val) this.payLook();
      },
    },
  },
};
</script>

<style scoped>
</style>