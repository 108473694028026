<template>
  <div>
    <p v-if="couponList.length == 0">暂无优惠券可选</p>
    <el-row v-else>
      <el-col
        :span="6"
        class="cu m-t-5"
        :style="couponBg(item)"
        v-for="(item, index) in couponList"
        :key="index"
        style="position: relative"
        @click.native="useCoupon(item)"
        :offset="Curr(index)"
      >
        <div class="coupon f-l-l">
          <div class="p-t-5 moneyTitle">
            <p class="marginZero" style="height: 35px">
              ￥<span class="f-s-40">{{ item.amount }}</span>
            </p>
            <div class="f-s-14 p-l-2">
              <!-- <p class="marginZero use text-c b-r-8 cu">立即使用</p> -->
              <p class="marginZero yichuOne p-t-5" style="margin: 0">
                {{ item.startTime }}-{{ item.endTime }}
              </p>
            </div>
          </div>
          <p class="text-c yichuOne f-s-10" :class="manCla(item)">{{ item.cname }}</p>
        </div>
        <div class="couponRight f-l-l text-c m-l-3">
          <p class="text-tb color-w l-s-4 m-t-8">{{ couText(item) }}</p>
        </div>
        <el-image :src="invalid" class="imgSt" v-if="item.couponState == '1' || item.couponState == '2'"></el-image>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  props: {
    couponList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      couponO: require("@/assets/img/coupon/couponO.png"),
      couponB: require("@/assets/img/coupon/couponB.png"),
      invalid: require("@/assets/img/coupon/newS.png"),
      orderO: {
        background:
          "url(" + require("@/assets/img/coupon/newO.png") + ") no-repeat",
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
      },
      orderB: {
        background:
          "url(" + require("@/assets/img/coupon/newH.png") + ") no-repeat",
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
      },
    };
  },
  methods: {
    Curr(i){
      let path = this.$route.path
      if(path != "/course/toOneCenter") return 0
      if(i % 3 != 0){
        return 1
      }
    },
    // 去使用优惠券
    use(item){
      this.goCurr(item)
    },
    // 领取优惠券
    receiveCoupon(item){
      let data = {
        aid : this.$route.query.aid,
        uid : this.$f.uid()
      }
      this.Api.Coupon.receiveCou(data).then(res => {
        let text = "领取成功，是否立即去买课"
        let type = "success"
        if(res.status == "200"){
          item.couponState = 3
        }else {
          text = "领取失败,请刷新重试",
          type = "error"
        }
        this.$confirm(text, '提示', {
          confirmButtonText: '去买课',
          cancelButtonText: '回首页',
          type
        }).then(() => {
          if(res.status == "200"){
            this.goCurr(item)
          }
        }).catch(() => {
          this.$router.push("/")       
        });
      })
    },
    // 跳转到课程详情
    goCurr(item){
      this.$router.push({
        path:"/course/toOneCenter",
        query:{cid:item.cid}
      })
    },
    // 使用优惠券
    useCoupon(item){
      if(this.$route.path == "/course/toOneCenter"){
        return false
      }
      switch(item.couponState){
        case 1:
          this.$message.info("优惠券已使用");
          break;
        case 2:
          this.$message.info("优惠券已失效");
          break;
        case 3:
          if(!this.$f.uid()) return
          this.use(item) // 去使用优惠券
          break;
        case 4:
          if(!this.$f.uid()) return
          this.receiveCoupon(item) // 领取优惠券
          break;
        case 5:
          this.$message.error("优惠券领取完了");
          break
        default :
         this.$message.error("未检测到优惠券属性，请重试或刷新")
         break
      }
    },
    // 满减class
    manCla(item){
      switch(item.couponState){
        case 1:
          return 'manjianO';
        case 2:
          return 'manjianO';
        case 5:
          return 'manjianO';
        default:
          return 'manjian';
      }
    },
    couponBg(item) {
      switch (item.couponState) {
        case 1:
          return this.orderB;
        case 2:
          return this.orderB;
        case 5:
          return this.orderB
        default:
          return this.orderO;
      }
    },
    couText(item) {
      switch (item.couponState) {
        case 1:
          return "已使用";
        case 2:
          return "已失效";
        case 3:
          return "立即使用";
        case 4:
          return "立即领取";
        case 5:
          return "已领取完"
      }
    },
  },
};
</script>

<style scoped>
.imgSt {
  position: absolute;
  left: 150px;
  top: 5px;
}
.manjian {
  background: linear-gradient(90deg, #fefeff 0%, #f6a638 100%);
  margin-bottom: 0;
  color: black;
  margin-top: 8px;
  padding-left: 8px;
}
.manjianO {
  background: linear-gradient(90deg, #fefeff 0%, #e4e4e4 100%);
  margin-bottom: 0;
  color: black;
  margin-top: 8px;
  padding-left: 8px;
}
.couponRight {
  width: 15%;
}
.use {
  background: red;
}
.coupon {
  width: 185px;
  height: 86.5px;
  color: white;
}
.moneyTitle {
  /* border-bottom: 1px solid white; */
  height: 50px;
}
.marginZero {
  margin: 0 !important;
}
</style>