<template>
  <div class="m-b-20">
    <!-- <h1 class="m-t-40">师资介绍</h1> -->
    <ClassTitle class="m-t-40 m-b-20" title="师资介绍/" englishTitle="Teacher introduction" />
    <TeacherItem :teacherList="teacherList" v-if="teacherList.length > 0" />

    <el-row v-else>
      <el-col
        :span="5"
        style="position: relative; width: 20%"
         v-for="(item, index) in skeletonNum"
        :key="index"
        :style="{marginLeft:index >= 1 ? '6.6%' : '0'}"
      >
        <el-skeleton style="width: 100%" :loading="true" animated>
          <template slot="template">
            <el-skeleton-item
              variant="image"
              style="width: 100%; height: 300px"
            />
          </template>
        </el-skeleton>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import TeacherItem from "@/components/public/Teachers/teacherItem.vue";

export default {
  components:{
    TeacherItem
  },
  data(){
    return {
      teacherList:[],
       skeletonNum: [
        { id: 1 },
        { id: 2 },
        { id: 3 },
        { id: 4 },
      ],
    }
  },
  created(){
    this.getTeacherList()
  },
  methods:{
    getTeacherList(){
      this.Api.Login.getTeacherList().then(res => {
        this.teacherList = res.data
        this.teacherList.push(this.teacherList[0])
        this.teacherList.push(this.teacherList[1])
        console.log(this.teacherList)
      })
    }
  }
}
</script>

<style scoped>

</style>