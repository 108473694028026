<template>
  <div>
    <el-table :data="tableData" style="width: 100%" border>
      <el-table-column prop="orderNumber" label="订单编号"> </el-table-column>
      <el-table-column prop="courseTitle" label="名称"> </el-table-column>
      <el-table-column prop="coursePrice" label="价格" width="180"></el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  props:{
    tableData:{
      type:Array,
      default:() => {
        return []
      }
    }
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
</style>