import axios from "@/plugins/axios";

export default {
  userIfSignAgreement(data) {// 获取用户是否签署协议
    return axios.post("agreement/userIfSignAgreement", data);
  },
  agreemenById(data){  // 获取协议详情
    return axios.post("agreement/agreemenById",data)
  },
  updSignAgreementState(data){  // 更新协议状态
    return axios.post("agreement/updSignAgreementState",data)
  },
  updUserSignAgreementInfo(data){  // 用户签署协议信息存储
    return axios.post("agreement/updUserSignAgreementInfo",data)
  },
  agreementList(data){  // 获取协议列表
    return axios.post("agreement/agreementList",data)
  }
};
