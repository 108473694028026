<template>
  <div>
    <!-- 微信支付 -->
    <vue-qr :size="200" :text="wxPayUrl" :logoSrc="icon" v-if="wxPayUrl != ''"> </vue-qr>
  </div>
</template>

<script>
import VueQr from "vue-qr";
export default {
  props:{
    icon:String
  },
  data() {
    return {
      wxPayUrl: "",
    };
  },
  components: {
    VueQr,
  },
};
</script>

<style scoped>
</style>