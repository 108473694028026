<template>
  <div class="side">
    <div
      v-for="item in list"
      class="cont"
      :key="item.id"
      @click="susp(item)"
      :id="item.id"
      :style="{ position: item.id == 3 ? 'relative' : '' }"
    >
      <i class="f-s-18 m-t-10" :class="item.icon"></i>
      <p class="f-s-10 marginNone">{{ item.text }}</p>
      <div
        class="ewBox"
        v-if="item.id == 'wx'"
        :style="{ backgroundImage: 'url(' + leftewm + ')' }"
      ></div>
      <el-image
        :src="downApp"
        class="downApp"
        v-if="item.id == 'downApp'"
      ></el-image>
    </div>

    <el-backtop></el-backtop>
  </div>
</template>

<script>
// import "./qidian"
export default {
  data() {
    return {
      list: [
        {
          id: "qq1",
          text: "课程咨询",
          icon: "el-icon-chat-dot-round",
          name: "qqonline",
        },
        {
          id: 2,
          text: "企业团培",
          icon: "el-icon-office-building",
        },
        {
          id: "wx",
          text: "官方微信",
          icon: "el-icon-user",
        },
        {
          id: "qq2",
          text: "学员服务",
          icon: "el-icon-school",
        },
        {
          id: "downApp",
          text: "下载app",
          icon: "el-icon-sort",
          // icon:require("@/assets/img/app.png")
        },
        {
          id: 5,
          text: "返回顶部",
          icon: "el-icon-top",
        },
      ],
      leftewm: require("@/assets/img/home/leftewm.jpg"),
      downApp: require("@/assets/img/home/downApp.png"),
    };
  },
  created() {
    window.addEventListener("scroll", this.handleScroll, true);
  },
  methods: {
    handleScroll() {
      let scrolltop =
        document.documentElement.scrollTop || document.body.scrollTop;
      scrolltop > 30 ? (this.gotop = true) : (this.gotop = false);
    },
    susp(item) {
      switch (item.id) {
        case 4:
          window.open("https://wpa1.qq.com/yOEafqvk?_type=wpa&qidian=true");
          break;
        case 2:
          this.$router.push("/EnterpriseTrain/toEnterpriseTrain");
          break;
        case 5:
          this.goTop();
          break;
      }
    },
    serve() {},
    // 滚动到顶部
    goTop() {
      var timer = setInterval(function () {
          document.getElementById("app").scrollTop = 0
        if (document.getElementById("app").scrollTop === 0) {
          clearInterval(timer);
        }
      }, 30);
    },
  },
  mounted() {
    const s = document.createElement("script");
    s.id = "qd2885771196a7d66bc096685aad6e541839ce974a5f";
    s.charset = "utf-8";
    s.async;
    s.defer;
    s.type = "text/javascript";
    s.src =
      "https://wp.qiye.qq.com/qidian/2885771196/a7d66bc096685aad6e541839ce974a5f";
    document.body.appendChild(s);

    const d = document.createElement("script");
    d.id = "qd2885771196d6878100e53f9c48bdaa9eed74f320f2";
    d.charset = "utf-8";
    d.async;
    d.defer;
    d.type = "text/javascript";
    d.src =
      "https://wp.qiye.qq.com/qidian/2885771196/d6878100e53f9c48bdaa9eed74f320f2";
    document.body.appendChild(d);
  },
};
</script>

<style scoped>
#wx:hover .ewBox {
  display: block !important;
  transition: all 0.3s;
}
#downApp:hover .downApp {
  display: block !important;
  transition: all 0.3s;
}
.downApp {
  width: 104px;
  height: 104px;
  color: #363636;
  display: none;
  text-align: center;
  position: absolute;
  left: -106px;
  top: 227px;
}
.ewBox {
  width: 104px;
  color: #363636;
  display: none;
  text-align: center;
  padding-top: 104px;
  position: absolute;
  left: -106px;
  top: 113px;
  background-image: url(https://www.xzjy365.com/images/fc/leftewm.png);
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #f1f1f1;
}
.side {
  position: fixed;
  right: 2%;
  top: 30%;
  border: 1px solid #f1f1f1;
  background: #fff;
  width: 52px;
  border-radius: 4px;
  z-index: 20;
}
.side div {
  border-bottom: 1px solid #f1f1f1;
  color: #888;
}
.side .cont:hover {
  background: #f67524;
  color: #fff;
  cursor: pointer;
}
.side div p {
  margin-top: 0;
}
</style>