<template>
  <div>
    <el-carousel :interval="5000" arrow="hover" style="height:360px" v-if="carouseList.length > 0">
      <el-carousel-item v-for="item in carouseList" :key="item.id" @click.native="carouseClick(item)" style="height:360px">
        <el-image :src="item.url" style="width:100%;height:100%"></el-image>
      </el-carousel-item>
    </el-carousel>
     <el-skeleton style="width: 100%" :loading="true" animated v-else>
       <template slot="template">
         <el-skeleton-item
          variant="image"
          style="width: 100%; height: 360px;"
        />
       </template>
     </el-skeleton>
  </div>
</template>

<script>
export default {
  data() {
    return {
      carouseList:[], // 存放轮播图
    };
  },
  created(){
    this.GetCarouselMap()   // 获取轮播图
  },
  methods:{
    GetCarouselMap(){  // 获取轮播图
      this.Api.Login.GetCarouselMap().then(res => {
        if(res.status == 200){
          this.carouseList = res.data
        }
      })
    },
    carouseClick(item){
      window.open(item.plink)
    }
  }
};
</script>

<style scoped>
.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
.el-carousel /deep/ .el-carousel__container  {
  height: 360px !important;
}
</style>
