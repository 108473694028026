<template>
  <div>
    <el-row class="text-l">
      <el-image :src="imgSrc" style="width:30px;height:30px" v-if="imgSrc != ''" class="f-l-l m-t-5"></el-image>
      <p class="f-s-28 f-l-l" :class="imgSrc != '' ? 'm-l-13' : ''" style="font-weight:600">{{title}}</p>
      <p class="color-title f-s-16 m-l-13 f-l-l" style="line-height:44px" v-if="imgSrc == ''">{{englishTitle.toUpperCase()}}</p>
      <!-- <span class="f-l-r color-title f-s-16 cu">查看更多<i class="el-icon-arrow-right"></i></span> -->
    </el-row>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props:{
    title:{
      type:String,
      default:""
    },
    englishTitle:{
      type:String,
      default:""
    },
    imgSrc:{
      type:String,
      default:""
    }
  }
};
</script>

<style scoped>
p {
  margin-top: 0;
  margin-bottom: 0;
}
</style>