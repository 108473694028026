export default {
  namespaced: true,
  state: {
    wsType: false,
    wsData: ''
  },
  mutations: {
    // 修改ws的状态  true 连接成功
    editWsType(state, val) {
      state.wsType = val
    },
    editWsData(state,val){
      state.wsData = val
    }
  },
  actions: {},
};
