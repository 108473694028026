import axios from "@/plugins/axios";

export default {
  getNewList() {
    // 获取全部的BIM咨询列表
    return axios.get("information/newsList");
  },
  getNewDetial(id) {
    //   获取新闻咨询的详情
    return axios.get("information/newDetial?id=" + id)
  }
};
