import Vue from "vue";
import Api from "@/api/index";
import check from '@/utils/check'
import overallFun from '@/plugins/function'
import GLO from '@/utils/global';

Vue.prototype.Api = Api;  // 请求类
Vue.prototype.Check = check  // 校验类
Vue.prototype.$f = overallFun
Vue.prototype.GLO = GLO
// 全局获取缓存数据
Vue.prototype.resetSetItem = function (key, newVal) {
  if (key === 'watchStorage') {
    // 创建一个StorageEvent事件
    var newStorageEvent = document.createEvent('StorageEvent');
    const storage = {
      setItem: function (k, val) {
        sessionStorage.setItem(k, val);
        // 初始化创建的事件
        newStorageEvent.initStorageEvent('setItem', false, false, k, null, val, null, null);
        // 派发对象
        window.dispatchEvent(newStorageEvent)
      }
    }
    return storage.setItem(key, newVal);
  }
}
