<template>
  <div>
    <div>
    <el-radio v-model="radio" label="1" border>微信</el-radio>
    <el-radio v-model="radio" label="2" border>支付宝</el-radio>
  </div>
  </div>
</template>

<script>
export default {
  data(){
    return {
      radio:"1"
    }
  },
  watch:{
    radio:{
      deep:true,
      immediate:true,
      handler(val){
        this.$emit("editPayRadio",val)
      }
    }
  }
}
</script>

<style scoped>

</style>