<template>
  <div>
    <el-drawer
      title="消息中心"
      :visible.sync="drawer"
      :direction="direction"
      :before-close="$f.openOrcloseMsg"
    >
      <span>我来啦!</span>
    </el-drawer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      direction:"rtl",
    };
  },
  computed:{
    drawer(){
      return this.$store.state.overall.msgType
    }
  },
};
</script>

<style lang="less" scoped>
</style>