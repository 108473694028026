<template>
  <div>
    <p class="f-s-24" :class="colorType == 'live' ? 'liveColor' : 'text' ">{{text}}</p>
  </div>
</template>

<script>
export default {
  props:{
    text:{
      type:String,
      default:"信息"
    },
    colorType:{
      type:String,
      default:""
    }
  }
}
</script>

<style scoped>
.text {
  border-left: 4px solid rgba(25,118,232,1);
  padding-left: 10px;
}
.liveColor {
  padding-left: 10px;
  border-left: 4px solid #FC8840;
  color: #FC8840;
  text-align: left;
}
</style>