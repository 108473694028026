var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('ClassTitle',{staticClass:"m-t-40 m-b-20",attrs:{"title":"免费公开课","imgSrc":"http://webxiaozhu.xzjy365.com/images/%E5%85%8D%E8%B4%B9%E5%85%AC%E5%BC%80%E8%AF%BE2ElYEU"}}),(_vm.freeClassList.length > 0)?_c('el-row',_vm._l((_vm.freeClassList),function(item,index){return _c('el-col',{key:item.id,staticClass:"bg-white classType openClass",staticStyle:{"width":"23.4%"},style:({
        marginLeft: index % 4 != 0 ? '1.6rem' : '',
        marginTop: index > 3 ? '1.6rem' : '',
      }),attrs:{"span":6},nativeOn:{"click":function($event){return _vm.goDetial(item)}}},[_c('p',{staticClass:"title text-l",staticStyle:{"margin-top":"0"}},[_vm._v(_vm._s(item.courseTitle))]),_c('p',{staticClass:"time text-l yichuOne",staticStyle:{"margin":"0"}},[_vm._v(" "+_vm._s(item.countStarttime)+"至"+_vm._s(item.countStopTime)+" | "+_vm._s(item.ccount)+"课时 ")]),_c('el-row',[_c('div',_vm._l((item.tlist),function(t,index){return _c('div',{key:index,staticClass:"teacherPic"},[_c('el-avatar',{attrs:{"src":t.turl}}),_c('p',{staticClass:"teacherName"},[_vm._v(_vm._s(t.tname))])],1)}),0)]),_c('div',{staticClass:"f-s-14 m-t-20 b-t-1 p-t-10"},[_c('div',{staticClass:"f-l-l",staticStyle:{"height":"40px","line-height":"40px"}},[_vm._v(" "+_vm._s(item.courseXnum)+"人已上课 ")]),_c('el-button',{staticClass:"f-l-r b-r-18 class-btn-shadow",staticStyle:{"background":"linear-gradient(","padding":"10px","box-shadow":"0px 2px 4px 0px rgba(240, 128, 65, 0.3)","min-width":"80px"},attrs:{"icon":"el-icon-video-play","type":item.type == 1 ? 'warning' : 'danger',"disabled":item.type != 1}},[_vm._v(_vm._s(item.type == 1 ? "免费" : "已售罄"))])],1)],1)}),1):_c('el-row',{staticClass:"text-l"},_vm._l((_vm.skeletonNum),function(item,index){return _c('el-col',{key:index,staticClass:"openClass classType",staticStyle:{"width":"23.4%"},style:({
        marginLeft: index % 4 != 0 ? '1.6rem' : '',
        marginTop: index > 3 ? '1.6rem' : '',
      }),attrs:{"span":6}},[_c('el-skeleton',{staticStyle:{"height":"285px"},attrs:{"loading":true,"animated":"","rows":8}},[_c('el-skeleton-item',{staticStyle:{"width":"100%","height":"285px"}})],1)],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }