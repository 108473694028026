import axios from "@/plugins/axios";

export default {
  getExamList(data) {// 获取考试列表
    return axios.get("exam/getExamList",data);
  },
  addExamInfo(data){  // 添加考试报名信息
      return axios.post("exam/addExamInfo",data)
  },
  getUserExamList(data){  // 获取用户报名的信息
    return axios.post("exam/getUserExamList",data)
  },
  getUserInfo(data){  // 获取用户信息
    return axios.post("exam/getUserInfo",data)
  },
  getExamGroupList(data){  // 获取学习组信息
    return axios.post("exam/getExamGroupList",data)
  },
  getExamDataList(data){  // 获取学习资料
    return axios.post("exam/getExamDataList",data)
  },
  getUserDiscount(data){  // 获取用户购买的课程进行报名优惠
    return axios.post("exam/getUserDiscount",data)
  },
  getUserExamInfoById(data){  // 获取用户报名的详情
    return axios.post("exam/getUserExamInfoById",data)
  },
  payExamOrder(data){  // 订单支付
    return axios.post("exam/payExamOrder",data)
  },
  getPatType(data){  // 检测是否支付完成
    return axios.post("exam/examOrderStater",data)
  },
  getExamDetial(data){  // 获取考试详情
    return axios.post("exam/getExamDetial",data)
  },
  getExamFirstList(){  // 获取一级考试列表
    return axios.post("exam/getExamFirstList")
  }

};
