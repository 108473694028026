import axios from "@/plugins/axios";


export default {
    login(data){  // 登录
        return axios.post("user/login",data)
    },
    codeLogin(data){ // 验证码登录
        return axios.post("user/VerificationCodeLogin",data)
    },
    editPwd(data){  // 修改密码
        return axios.post("user/updatePwd",data)
    },
    register(data){  // 注册
        return axios.post("user/register",data)
    },
    GetCarouselMap(){  // 获取轮播图
        return axios.get("homepage/getCra")
    },
    getFreeClassList(){  // 获取免费课程 
        return axios.get("free/getClass")
    },
    getHomeImg(){   // 获取首页精品课程下的图片
        return axios.get("homepage/homeImg")
    },
    getExcellentClassList(){  // 获取首页精品课程下的列表
        return axios.get("homepage/getExcellentClassList")
    },
    getCode(data){  // 获取验证码
        return axios.post("user/sendOutCode",data)
    },
    test(){
        return axios.get("Ahh")
    },
    getTeacherList(){ // 获取师资列表
        return axios.get("teacher/getTeacherList")
    },
    getLiveList(data){  // 获取近期直播列表
        return axios.post("live/getLiveList",data)
    },
    bookLiveBroadcast(data){ // 预约直播
        return axios.post("live/bookLiveBroadcast",data)
    },
    itemFirstLevel(data){  // 获取分类
        return axios.post("item/itemFirstLevel",data)
    },
    getSecLevel(data) {  // 获取分类的二级菜单
        return axios.post("item/itemSecondLevelByFirstId",data)
    }
}