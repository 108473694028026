// import Vue from 'vue'
import axios from "axios";
// MessageBox
import { Loading, Message, MessageBox } from 'element-ui';
import GLO from '@/utils/global';
import Wefl from '../../public/wefloat'
import $f from '../plugins/function'
import router from '../router/index'
import store from '../store/index'
axios.defaults.baseURL = GLO.Ip
let loading        //定义loading变量
function startLoading() {    //使用Element loading-start 方法
  loading = Loading.service({
    lock: true,
    text: Wefl.loadText,
    background: 'rgba(0, 0, 0, 0.7)'
  })
}
function endLoading() {    //使用Element loading-close 方法
  loading.close()
}

let needLoadingRequestCount = 0
export function showFullScreenLoading() {
  if (needLoadingRequestCount === 0) {
    startLoading()
  }
  needLoadingRequestCount++
}

export function tryHideFullScreenLoading() {
  if (needLoadingRequestCount <= 0) return
  needLoadingRequestCount--
  if (needLoadingRequestCount === 0) {
    endLoading()
  }
}
//post请求头
axios.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded;charset=UTF-8";
axios.defaults.headers["Content-Type"] = "text/html;charset=utf-8";
axios.defaults.headers["Content-Type"] = "application/json";
//设置超时
axios.defaults.timeout = 10000;

axios.interceptors.request.use(
  config => {
    let belong = JSON.parse(localStorage.getItem("belong"))
    if (belong != null && belong != undefined && belong != '' && belong != undefined) {
      if (config.method == 'get' && config.params != undefined) {
        config.params.belong = belong
      } else if (config.data != undefined) {
        let data = JSON.parse(JSON.stringify(config.data))
        data.belong = belong
        config.data = data
      }
    } else {
      if (config.method == 'get' && config.params != undefined) {
        config.params.belong = null
      } else if (config.params != undefined) {
        let data = JSON.parse(JSON.stringify(config.data))
        data.belong = null
        config.data = data
      }
    }

    if (JSON.parse(localStorage.getItem("Bt")) != null && JSON.parse(localStorage.getItem("Bt")) != undefined) {
      if (config.method == 'get' && config.params != undefined) {
        config.params.registerPage = JSON.parse(localStorage.getItem("Bt"))
      } else if (config.params != undefined) {
        let data = JSON.parse(JSON.stringify(config.data))
        data.registerPage = JSON.parse(localStorage.getItem("Bt"))
        config.data = data
      }
    } else if (config.params != undefined) {
      if (config.method == 'get') {
        config.params.registerPage = null
      } else {
        let data = JSON.parse(JSON.stringify(config.data))
        data.registerPage = null
        config.data = data
      }
    }
    if (JSON.parse(localStorage.getItem("user")) == null) {
      return config
    } else {
      config.headers['token'] = JSON.parse(localStorage.getItem("user")).code
      if (config.headers['token'] == undefined || config.headers['token'] == null || config.headers['token'] == '' || config.headers['token'].length == 11) {
        $f.removeLocal([
          "token",
          "userInfo",
          "setToken",
          "loginPhone",
          "user",
          "uid",
        ]);
        store.commit("overall/editLoginType", false)
        store.commit("overall/editUser", {})
        MessageBox.alert('新版本更新,请重新登录', '提示', {
          confirmButtonText: '确定',
          type: 'warning'
        }).then(() => {
          router.replace({ path: "/" })
          location.reload();
        })
      }
      return config
    }
  },
  error => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  response => {
    if (response.data.status == 5020) {
      $f.removeLocal([
        "token",
        "userInfo",
        "setToken",
        "loginPhone",
        "user",
        "uid",
      ]);
      store.commit("overall/editLoginType", false)
      store.commit("overall/editUser", {})
      MessageBox.alert('该账号已在别处登录', '提示', {
        confirmButtonText: '确定',
        type: 'warning'
      }).then(() => {
        router.replace({ path: "/" })
        location.reload();
      })
      return false
    } else if (response.data.status == 5023) {
      $f.removeLocal([
        "token",
        "userInfo",
        "setToken",
        "loginPhone",
        "user",
        "uid",
      ]);
      store.commit("overall/editLoginType", false)
      store.commit("overall/editUser", {})
      MessageBox.alert('账号异常,请重新登录', '提示', {
        confirmButtonText: '确定',
        type: 'warning'
      }).then(() => {
        router.replace({ path: "/" })
        location.reload();
      })
      return false
    }
    if (response.status == 200) {
      if (response.data.status == 500) {
        Message.error(response.data.msg)
      }
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  },
  error => {
    // if(!Wefl.editionType) tryHideFullScreenLoading()
    console.log(error);
    // alert(`异常请求：${JSON.stringify(error.message)}`)
  }
);



export default {
  post(url, data) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url,
        data: data,
      })
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        });
    })
  },

  get(url, data) {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url,
        params: data,
      })
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  }
};

