import axios from "@/plugins/axios";

export default {
  getExceAllList(data) {// 获取精品课程的全部列表
    return axios.post("homepage/getAllExceClassList",data);
  },
  // getExceAllList() {// 获取精品课程的全部列表
  //   return axios.get("homepage/getAllExceClassList");
  // },
  gerClassDetial(data) {  // 获取课程详情头部信息
    return axios.get("curric/getDeial",data)
  },
  getcidCourseDetails(data) {  // 获取课程详情的详情内容
    return axios.get("curric/getcidCourseDetails",data)
  },
  getCourseCatalogue(data){  // 获取课程目录
    return axios.get("curric/getCourseCatalogue",data)
  },
  getTeacher(data){  // 获取讲师简介
    return axios.get("curric/getTeacher",data)
  },
  getRecommendCourse(){  // 获取课程推荐
    return axios.get("curric/recommendCourse")
  },
};
