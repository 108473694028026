import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/Home.vue";

const curriculum = () => import("@/views/curriculum/curriculum.vue"); // 课程咨询
const currDetial = () => import("@/views/curriculum/detial.vue");  // 课程详情
const dataDownload = () => import("@/views/dataDownload/dataDownload.vue"); // 资料下载
// const consulting = () => import("@/views/consulting/consulting.vue"); // BIM咨询
const stock = () => import("@/views/stock.vue")
const lookLive = () => import("@/views/lookLive/lookLive.vue")  // 观看直播
const learningCenter = () =>
  import("@/views/learningCenter/learningCenter.vue"); // 学习中心
const enterprise = () => import("@/views/enterprise/enterprise.vue") // 企业培训
const player = () => import("@/views/player/player.vue")  // 视频播放中心
const notFound = () => import("@/views/404.vue"); // 404页面
const coupon = () => import("@/views/coupon/coupon.vue")  // 优惠券
const receiveCou = () => import("@/views/receiveCou/receiveCou.vue")  // 优惠券
const payResult = () => import("@/components/pay/payResult.vue") // 支付回调界面
const live = () => import("@/views/live/live.vue")  // 进入直播界面
const sucpen = () => import("@/views/suspension.vue")
const test = () => import("@/views/test.vue")
// const NewsDetails = () => import("@/views/consulting/NewsDetails.vue") // 咨询详情
Vue.use(VueRouter);

const title = "【小筑教育官网】国内BIM实战培训口碑品牌";
const keywords = "bim,bim培训,bim技术,bim学习,bim软件,bim考试,revit,revit培训,revit软件,revit下载";
const description = "【小筑教育官网】专注BIM技术实战培训,致力于建工新技术的普及。 旨在通过互联网在线学习平台,集结BIM一线专家,让更多人零基础快速学习和使用BIM技术,提升个人技能,让工程更高效。"
const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      requireAuth: false,
      title,
      content: {
        keywords,
        description
      }
    }
  },
  // 课程中心
  {
    path: "/curriculum",
    component: curriculum,
    meta: {
      title: title + "课程中心",
      content: {
        keywords,
        description
      }
    }
  },
  {
    path: "/lookLive",
    component: lookLive,
    meta: {
      title: title + "观看直播",
      keepAlive: true,
      content: {
        keywords,
        description
      }
    }
  },
  // 企业培训
  {
    path: "/EnterpriseTrain/toEnterpriseTrain",
    component: enterprise,
    meta: {
      title: title + "企业培训",
      keepAlive: true,
      content: {
        keywords,
        description
      }
    }
  },
  // 播放中心
  {
    path: "/player",
    component: player,
    meta: {
      title: title + "播放中心",
      content: {
        keywords,
        description
      }
    }
  },
  // 优惠券
  {
    path: "/coupon",
    component: coupon,
    meta: {
      title: title + "优惠券",
      content: {
        keywords,
        description
      }
    }
  },
  // 推送 测试
  {
    path: "/stock",
    component: stock,
    meta: {
      title: "推送"
    }
  },
  {
    path: "/course/toOneCenter",
    name: "currDetial",
    component: currDetial,
    meta: {
      title: title + "课程详情",
      content: {
        keywords,
        description
      }
    },
  },
  // 资料下载
  {
    path: "/dataDownload",
    component: dataDownload,
    meta: {
      title: title + "资料下载",
      keepAlive: true,
      content: {
        keywords,
        description
      }
    },
  },
  // BIM咨询
  // {
  //   path: "/consulting",
  //   component: consulting,
  //   meta: {
  //     title: title + "BIM咨询",
  //     keepAlive: true,
  //     content: {
  //       keywords,
  //       description
  //     }
  //   },
  // },
  // 咨询详情
  // {
  //   path: "/NewsDetails",
  //   component: NewsDetails,
  //   meta: {
  //     title: title + "咨询详情",
  //     keepAlive: true,
  //     content: {
  //       keywords,
  //       description
  //     }
  //   },
  // },
  // 学习中心
  {
    path: "/learningCenter",
    component: learningCenter,
    meta: {
      title: title + "学习中心",
      requireAuth: true,
      content: {
        keywords,
        description
      }
    },
  },
  // 领取优惠券
  {

    path: "/personal/coponSrc",
    name: "receiveCou",
    component: receiveCou,
    meta: {
      title: title + "领取优惠券",
      content: {
        keywords,
        description
      }
    },
  },
  // 支付回调界面
  {
    path: "/payResult",
    component: payResult,
    meta: {
      title: title + "订单支付",
      content: {
        keywords,
        description
      }
    },
  },
  // 进入直播间
  {
    path: "/live/getLiveBroadcast",
    component: live,
    name: "goLive",
    meta: {
      title: title + "进入直播",
      content: {
        keywords,
        description
      }
    },
  },
  {
    path: "/suspen",
    component: sucpen,
    meta: {
      title: title + "悬浮栏",
      content: {
        keywords,
        description
      }
    },
  },
  // 测试
  {
    path: "/test",
    component: test,
    meta: {
      title: title + "测试",
      content: {
        keywords,
        description
      }
    },
  },
  // 404
  {
    path: "/404",
    name: "notFound",
    component: notFound,
  },
  {
    path: "*", // 此处需特别注意置于最底部
    redirect: "/404",
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

export default router;
