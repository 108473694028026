import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import overallFun from '@/plugins/function'
import { Message } from 'element-ui';

import "element-ui/lib/theme-chalk/index.css";
import "@/assets/css/common.css"
import "@/utils/main/component"  // 全局组件
import "@/utils/main/prototypes"  // 挂载在vue原型链上的方法


Vue.use(ElementUI);


import Clipboard from 'v-clipboard'
 
Vue.use(Clipboard)
Vue.config.productionTip = false;

router.beforeEach((to, from, next) => {
  let token = localStorage.getItem("user");
  if (to.meta.requireAuth && token == null) {
    overallFun.goLogon()
    Message.error("请您先登录")
  } else {
    if (to.meta.content) {
      let head = document.getElementsByTagName('head');
      let meta = document.createElement('meta');
      document.querySelector('meta[name="keywords"]').setAttribute('content', to.meta.content.keywords)
      document.querySelector('meta[name="description"]').setAttribute('content', to.meta.content.description)
      meta.content = to.meta.content;
      head[0].appendChild(meta)
    }
    if (to.meta.title) {
      document.title = to.meta.title;
    }
    next();
  }
})

new Vue({
  router,
  store,
  render: (h) => h(App),
  mounted() {
    document.dispatchEvent(new Event('render-event'))
  }
}).$mount("#app");
