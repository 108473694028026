<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="50%"
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
      <div class="text-l" style="max-height:400px;overflow:auto">
        <div v-html="agreementInfo"></div>
        <div v-if="title == '查看协议'">
          <el-row class="f-s-18">
            <el-col :span="12">
              <p v-for="(item,i) in agreement.signAgreementInfos" :key="i" class="m-t-10">{{item}}</p>
            </el-col>
            <el-col :span="12">
              <p>乙方:北京小筑未来教育科技有限公司</p>
              <div style="heignt:60px;line-height:60px">
                <p class="f-l-l">盖章:</p>
                <el-image :src="company" style="width:120px;height:120px" class="m-l-20"></el-image>

              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submintNotice"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <Agreement ref="agreement" :cid="cid" :title="title" @getAgreementLsit="getAgreementLsit" />
  </div>
</template>

<script>
import Agreement from '@/components/learningCenter/class/item/agreement'
export default {
  components:{
    Agreement
  },
  props:{
    cid:Number,
    title:{
      type:String,
      default:"课前须知"
    },
    id:{
      default:null
    }
  },
  data() {
    return {
      dialogVisible: false,
      agreementInfo:"",
      agreement:{signAgreementInfos:[]},
      company:require("@/assets/img/home/company.png")
    };
  },
  methods:{
    handleClose(){
      this.dialogVisible = false
    },
    // 提交课前须知
    submintNotice(){
      if(this.title == "查看协议") {
        this.handleClose()
        return false
      }
      let data = {
        uid : this.$f.uid(),
        cid : this.cid
      }
      this.Api.Agreement.updSignAgreementState(data).then(res => {
        if(res.status == 200) {
          if(res.data.signstate == 3) {
            this.handleClose()
          } else if(res.data.signstate == 2) {
            res.data.signInfoList.forEach(item => {
              item.info = ""
            })
            this.$refs.agreement.signInfoList = res.data.signInfoList
            this.handleClose()
            this.$refs.agreement.dialogVisible = true
          }
        } else {
          this.$message.error(res.message)
        }
      })
    },
    agreemenById(){
      let data = {
        uid : this.$f.uid(),
        cid : this.cid
      }
      if(this.title == "查看协议") {
        data.cid = this.id
        data.id = this.cid
      }
      this.Api.Agreement.agreemenById(data).then(res => {
        if(res.status == 200){
          this.agreementInfo = res.data.agreement
          this.$refs.agreement.name = res.data.name
          if(this.title == "查看协议") {
            this.agreement = res.data
          }
        }
      })
    },
    getAgreementLsit(){
      this.$emit("getAgreementLsit")
    }
  },
  watch:{
    dialogVisible:{
      deep:true,
      immediate:true,
      handler(val){
        if(val){
          this.agreemenById()
        } else {
          this.agreementInfo = ""
          this.agreement = {signAgreementInfos:[]}
        }
      }
    }
  }
};
</script>

<style scoped>
</style>