<template>
  <div class="hello">
    <div style="height: 15px; background: #f9fafb"></div>
    <div style="width: 1200px; margin: 0 auto" class="text-l">
      <Classify />
    </div>
    <div style="width: 1200px; margin: 0 auto">
      <Carousel />
    </div>
    <Live />
    <div style="width: 1200px; margin: 0 auto">
      <OpenClass :freeClassList="freeClassList" />
      <ExcellentClass />
      <Teachers />
    </div>
    <div class="bg-white">
      <div style="width: 1200px; margin: 0 auto" class="">
        <ClassTitle
          class="p-t-40 m-b-20"
          title="合作企业/"
          englishTitle="Teacher introduction"
        />
      </div>
      <el-image :src="StrategicPartners" style="width: 1200px; margin: 0 auto">
        <div slot="error" class="image-slot">
          <el-skeleton style="width: 100%" :loading="true" animated>
            <template slot="template">
              <el-skeleton-item
                variant="image"
                style="width: 100%; height: 300px"
              />
            </template>
          </el-skeleton>
        </div>
      </el-image>
    </div>
  </div>
</template>

<script>
import Carousel from "@/components/public/Carousel/Carousel"; // 轮播
import OpenClass from "@/components/public/OpenClass/OpenClass"; // 免费公开课
import ExcellentClass from "@/components/public/ExcellentClass/ExcellentClass"; // 精品课程
import Teachers from "@/components/public/Teachers/Teachers";
import Live from "@/components/public/Live/live";
import Classify from "@/components/public/classify/classify"
export default {
  components: {
    Carousel,
    OpenClass,
    ExcellentClass,
    Teachers,
    Live,
    Classify
  },
  name: "HelloWorld",
  props: {
    msg: String,
  },
  data() {
    return {
      StrategicPartners: "",
      freeClassList: [], // 存放免费公开课列表
    };
  },
  created() {
    this.getFootUrl();
    this.getFreeClassList();
  },
  methods: {
    // 获取免费课列表
    getFreeClassList() {
      this.Api.Login.getFreeClassList().then((res) => {
        if (res.status == 200) {
          this.freeClassList = res.data;
        }
      });
    },
    getFootUrl() {
      let data = { ids: [83] };
      this.Api.Prise.getPicUrl(data).then((res) => {
        if (res.status == 200) {
          this.StrategicPartners = res.data[0].url;
        }
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.hello {
  width: 100%;
  height: auto;
  position: relative;
  background: #f9fafb;
}
</style>
