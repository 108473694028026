<template>
  <div>
    <div class="top-info" v-if="showTips">
      <div style="width:1200px;margin:0 auto;">
        <span class="info"></span>声明：本公司无第三方代收款账户，请认准小筑教育官方收款码！核实电话：010-57130711
        <span class="delete" @click="deleteinfo()"></span>
      </div>
    </div>
  <div class="xz-b" style="max-height:80px;height:80px;">
    <el-row>
      <el-col  :span="4">
        <el-image :src="url" style="width: 70%" class="f-l-l m-t-26 cu" @click="home"></el-image>
        <!-- <el-image :src="headerGif" class="headerGif"></el-image> -->
      </el-col>
      <el-col :span="user.uid == undefined ? 16 : 17">
        <el-menu
          :default-active="activedMenu($route.path)"
          class="el-menu-demo f-s-18 f-l-l"
          router
          mode="horizontal"
          active-text-color="#F08041"
          @select="handleSelect"
        >
          <el-menu-item
            class="elMentItem"
            v-for="item in menuItem"
            :key="item.index"
            :index="item.index"
            :route="item.route"
            >{{ item.text }}</el-menu-item
          >
            
        </el-menu>
         <el-menu mode="horizontal" router class="el-menu-demo f-s-18 f-l-r" v-if="user.uid != undefined">
          <el-menu-item index="5" class="elMentItem" route="/learningCenter">学习中心</el-menu-item>
        </el-menu>
        
        <el-input
          style="min-width:160px;width:200px"
          class="f-l-r m-t-24 searchClass"
          placeholder="请输入内容"
          v-model="searchText"
          v-show="calssSearchType == '2'"
          @keyup.enter.native="changeClassType"
          suffix-icon="el-icon-search">
        </el-input>
       
      </el-col>
      <el-col :span="4" class="text-l" v-if="user.uid == undefined">
        <el-button type="warning" class="f-l-r m-t-30" @click="goRegister" size="mini" style="
background: linear-gradient(90deg, #FF8A18 0%, #F7AE6D 0%, #F08041 100%);"
          >注册</el-button
        >
         <el-button type="text" class="f-l-r m-t-24 m-r-15 f-s-14" @click="goLogin" style="color:black"
          >登录</el-button
        >
      </el-col>
      <el-col
        :span="3"
        class="text-l cu"
        v-else
        style="position: relative"
        id="rightInfo"
      >
        <i class="el-icon-arrow-down el-icon--right f-l-r m-t-32"></i>

        <span class="f-l-r m-t-32 cu overflowHiding" style="max-width:70px">
          {{ user.uname }}
        </span>
        <div style="" id="dropO">
          <p
            class="cu f-s-14 dropItem "
            v-for="item in dropdown"
            
            :key="item.id"
            @click="signout(item.id)"
          >
            {{ item.name }}
          </p>
        </div>
        <el-badge
          :value="2"
          hidden
          class="item f-l-r m-t-24 m-r-10 cu"
          type="success"
          @click.native="$f.openOrcloseMsg()"
        >
          <el-avatar :src="user.headimgurl"></el-avatar>
        </el-badge>
      </el-col>
    </el-row>
  </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showTips:this.getininfo(),
      url: require("@/assets/img/logo@2x.png"),
      routes: [],
      dropdown: [
        {
          id: 1,
          name: "我的课程",
          disabled: false,
          divided: false,
        },
        {
          id: 2,
          name: "我的订单",
          disabled: false,
          divided: false,
        },
        {
          id: 3,
          name: "优惠券",
          disabled: false,
          divided: false,
        },
        {
          id: 4,
          name: "账户设置",
          disabled: false,
          divided: false,
        },
        {
          id: 5,
          name: "退出登录",
          disabled: false,
          divided: true,
        },
      ],
      searchText:'',
      menuItem: [
        {
          text: "首页",
          index: "1",
          route: "/",
        },
        {
          text: "课程中心",
          index: "2",
          route: "/curriculum",
        },
        {
          text: "企业团培",
          index: "6",
          route: "/EnterpriseTrain/toEnterpriseTrain",
        },
        // {
        //   text: "资料下载",
        //   index: "3",
        //   route: "/dataDownload",
        // },
        // {
        //   text: "BIM资讯",
        //   index: "4",
        //   route: "/consulting",
        // },
        // {
        //   text: "学习中心",
        //   index: "5",
        //   route: "/learningCenter",
        // },
      ],
      activeMenu:""
    };
  },
  created() {
    let user = this.$f.getLocal("user");
    if (user != null) {
      this.$store.commit("overall/editUser", user);
    }
    let token = this.$f.getLocal("token");
    if (token != null) {
      this.$store.commit("overall/editToken", token);
    }
  },
  methods: {
    deleteinfo(){
      document.cookie = 'xzjytipinfo=1';
      this.showTips=this.getininfo()
      this.$emit('setheight')
    },
    getininfo(){
      if(this.getCookie('xzjytipinfo')==1){
        return false;
      }else{
        return true;
      }
      
    },
    getCookie(name) {
      var reg = RegExp(name + '=([^;]+)');
      var arr = document.cookie.match(reg);
      if (arr) {
          return arr[1];
      } else {
          return '';
      }
    },
    changeClassType(){
      if(this.searchText != '') {
        this.$store.commit("learn/editSearchTextChange",this.searchText)
        this.searchText = ''
      } 
      this.$store.commit("learn/editClassSearchType",'1')
      if(this.$route.path != '/curriculum') this.$router.push('/curriculum')
    },
    handleSelect(key,keyPath){
      this.activedMenu(key,keyPath)
      // if()
    },
    home(){
      if(this.$route.path=="/") return false
      this.$router.push("/")
    },
    goLogin() {
      this.$f.goLogon();
    },
    goRegister() {
      this.$store.commit("overall/editLoginType", true);
      this.$store.commit("overall/editLoginActiveName", "fourth");
    },
    signout(command) {
      switch (command) {
        case 5:
          this.$confirm("确定要退出登录嘛", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "error",
          })
            .then(() => {
              this.$f.removeLocal([
                "token",
                "userInfo",
                "setToken",
                "loginPhone",
                "user",
                "uid",
              ]);
              this.$store.commit("overall/editLoginType", false);
              this.$store.commit("overall/editUser", {});
              this.$router.replace({ path: "/" });
              location.reload();
            })
            .catch(() => {});
          break;
        default:
          this.$router.push("/learningCenter");
          this.$store.commit("learn/editTabActive", command);
      }
    },
    activedMenu(val) {
      this.routes = this.$router.app._route.matched; //路由列表
      if(val == "5" && !this.$f.uid()){
        this.activedMenu(this.$route.path)
      }
      if(val != '/curriculum') this.$store.commit("learn/editClassSearchType",'2')
      if (val == "/") {
        return "1";
      } else if (val == "/curriculum" || val == "/course/toOneCenter") {
        return "2";
      } else if (val == "/dataDownload") {
        return "3";
      } else if (val == "/consulting" || val == "/NewsDetails") {
        return "4";
      } else if (val == "/EnterpriseTrain/toEnterpriseTrain") {
        return "6";
      } else {
        return "5";
      }
    },
  },
  computed: {
    user() {
      return this.$store.state.overall.user;
    },
    loginType() {
      return this.$store.state.overall.loginType;
    },
    // 1 input筛选  2 专业类型筛选
    calssSearchType(){
      return this.$store.state.learn.calssSearchType
    }
  },
  watch: {
    user: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val.uid == undefined) return false;
        // this.$notify({
        //   title: "小筑教育",
        //   message: "欢迎" + val.uname + "进入小筑教育",
        //   type: "success",
        // });
      },
    },
  },
};
</script>

<style scoped>
.searchClass >>> input:focus {
  border-color: #F08142;
}
.el-menu-demo >>> .elMentItem{
  height: 70px;
  padding:0;
  margin: 0 1.25rem;
  color: #333333;
}
.searchClass >>> input {
  border-radius: 18px;
}
.el-menu--horizontal >>> .el-menu-item:not(.is-disabled):focus, .el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
 color: rgb(240, 128, 65);
}
.el-menu-demo >>> .elMentItem :hover{
  color: rgb(240, 128, 65);
}
#rightInfo:hover #dropO {
  display: block;
}
#dropO {
  position: absolute;
  right: 0;
  top: 60px;
  z-index: 100;
  background: white;
  display: none;
}
.dropItem {
  margin: 0;
  padding: 10px;
}
.dropItem:hover {
  background-color: #ecf5ff;
  color: #66b1ff;
}
.el-menu.el-menu--horizontal {
  border: none;
}
.elMentItem {
  height: 5.5625rem;
  line-height: 5.5625rem;
  font-size: 1rem;
  padding: 0 1.25rem;
}
.name {
  height: 89px;
  line-height: 89px;
}
.top-info{
  height: 32px;
  line-height: 32px;
  background: #FFFAF2;
  font-size:15px;
  color:#f08041;
  text-align: left;
}
.top-info div{
  position: relative;
}
  .top-info .info{
    background: url(../../assets/img/info2x.png) center center no-repeat;
    width: 19px;
    height: 17px;
    display: inline-block;
    background-size: contain;
    vertical-align: middle;
    position: relative;
    top: -1px;
    margin-right:5px;
  }
  .delete{
    background: url(../../assets/img/delete2x.png) center center no-repeat;
    width:15px;
    height: 15px;
    display: inline-block;
    background-size: contain;
    vertical-align: middle;
    position: absolute;
    right:0px;
    top:8px;
    cursor: pointer;

  }
</style>
