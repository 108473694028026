const formal = true;   // 是否为正式版 false 本地   true 正式
const Ip = formal ? 'https://www.xzjy365.com/xiaozhuPcqt' : 'http://192.168.1.53:6767'   // axios 请求地址
const wsIp = formal ? ' https://www.xzjy365.com/xiaozhuPcqt' :'localhost:6767'  // webstock 请求地址
const Device = "https://www.xzjy365.com/" 
const locaHref = formal ? 'https://www.xzjy365.com' : 'http://localhost:8084/'
// https://xzjy365.com/
// "https://ceshi.xzjy365.com/xiaozhuPcqt"
// http://localhost:8082/
// http://192.168.1.23:6767/
const Mobile = "https://m.xzjy365.com/"
const wsType = false
export default {
    formal,Ip,wsIp,Device,Mobile,wsType,locaHref
}