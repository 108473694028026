<template>
  <div style="overflow: hidden; height: 376px" @mouseover="mouseover"
      @mouseleave="mouseleave">
    <el-row
      style="width: 10000px"
      class="box"
      
      :style="{ animationPlayState: !active ? 'running' : 'paused' }"
    >
      <el-col
        :span="5"
        v-for="(item, i) in teacherList"
        :key="i"
        class="b-r-8 f-l-l teacher cu"
        :style="{
          background: 'url(' + item.tHalfPhoto + ') no-repeat',
          backgroundSize: '100% 100%',
        }"
      >
        <div class=" text-l teacherName bg-white">
        老师:{{ item.tname }}
        </div>

        <div class="text-l f-s-14 jian">
          <p style="margin: 0; margin-top: 10px">老师：{{ item.tname }}</p>
          <div>
            <p class="f-l-l" style="margin: 0">简介：</p>
            <p class="f-l-l" style="margin: 0; width: 80%">
              {{ item.tcontent }}
            </p>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  props: {
    teacherList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      active: false,
    };
  },
  methods: {
    mouseover() {
      this.active = true;
    },
    mouseleave() {
      this.active = false;
    },
  },
};
</script>

<style scoped>
.box {
  width: 500%;
  /*动画名称*/
  animation-name: move;
  /*动画时间*/
  animation-duration: 60s;
  /*动画次数*/
  animation-iteration-count: infinite;
  /*匀速*/
  animation-timing-function: linear;
  animation-play-state:running

}
.box:hover .box{
  animation-play-state:paused
}
@keyframes move {
  /*动画起始位置*/
  0% {
    transform: translateX(0px);
  }
  /*动画结束状态*/
  100% {
    transform: translateX(-3200px);
  }
}
.jian {
  background: white;
  position: absolute;
  bottom: 0;
  width: 95%;
  height: 60%;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding-left: 5%;
  display: none;
}
.teacherName {
  position: absolute;
  bottom: 0;
  width: 95%;
  height: 40px;
  line-height: 40px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding-left: 5%;
}
.teacher {
  height: 376px;
  box-shadow: 0px 0px 4px 1px rgb(0 0 0 / 5%);
  width: 284px;
  margin-right: 39px;
  position: relative;
}
.teacher:hover .jian {
  display: block;
}
.teacher:hover .teacherName {
  display: none;
}
</style>