<template>
  <div>
    <!-- el-tab-pane 中引入组件会造成浏览器卡死 -->
    <!-- :title="loginText()" -->
    <!-- :show-close="false" -->

    <el-dialog
      :visible.sync="loginType"
      width="25%"
      :before-close="handleClose"
      :close-on-click-modal="false"
      :show-close="false"
    >
      <i
        class="el-icon-close f-s-24 cu"
        @click="handleClose"
        style="color: white; position: absolute; right: -35px; top: 0"
      ></i>
      <el-row class="f-s-18">
        <!-- <el-col
          :span="12"
          class="cu"
          @click.native="swichTabs('first')"
          v-show="activeName == 'first' || activeName == 'second'"
        >
          <span :class="activeName == 'first' ? 'activeClick' : ''"
            >账号密码登录</span
          >
        </el-col> -->
        <el-col
          :span="24"
          class="cu"
          @click.native="swichTabs('second')"
          v-show="activeName == 'first' || activeName == 'second'"
        >
          <span :class="activeName == 'second' ? 'activeClick' : ''"
            >验证码登录</span
          >
        </el-col>
        <el-col :span="24" v-show="activeName == 'third'">
          <span :class="activeName == 'third' ? 'activeClick' : ''"
            >忘记密码</span
          >
        </el-col>
        <el-col :span="24" v-show="activeName == 'fourth'">
          <span :class="activeName == 'fourth' ? 'activeClick' : ''"
            >注册账号</span
          >
        </el-col>
      </el-row>
      <div class="tab m-t-35 p-l-40 p-r-40">
        <el-tabs v-model="activeName">
          <!-- 账号密码登录 -->
          <el-tab-pane label="" name="first">
            <div>
              <el-input
                ref="fPhone"
                placeholder="请输入手机号"
                v-model="login.phone"
                class="input-with-select"
                clearable
                :maxlength="11"
                auto-complete="off"
              >
                <div slot="append">
                  <country-code-selector
                    :countryCode.sync="login.areaCode"
                  ></country-code-selector>
                </div>

                <el-button slot="prepend" icon="el-icon-user"></el-button>
              </el-input>
            </div>
            <el-input
              ref="fPassword"
              placeholder="请输入密码"
              v-model="login.password"
              class="input-with-select m-t-20"
              type="password"
              clearable
              auto-complete="new-password"
            >
              <el-button slot="prepend" icon="el-icon-lock"></el-button>
            </el-input>
          </el-tab-pane>
          <!-- 验证码登录 -->
          <el-tab-pane label="" name="second">
            <el-input
              ref="sPhone"
              placeholder="请输入手机号"
              v-model="code.phone"
              class="input-with-select"
              clearable
              :maxlength="11"
            >
            <div slot="append">
                  <country-code-selector
                    :countryCode.sync="code.areaCode"
                  ></country-code-selector>
                </div>
              <el-button slot="prepend" icon="el-icon-user"></el-button>
            </el-input>
            <el-input
              ref="sCode"
              placeholder="请输入验证码"
              v-model="code.code"
              class="input-with-select m-t-10"
              clearable
              :maxlength="4"
            >
              <el-button
                slot="prepend"
                icon="el-icon-chat-dot-square"
              ></el-button>
              <el-button
                slot="append"
                type="success"
                @click="getCode"
                :disabled="wait != 60"
                >{{
                  wait == "60" ? "获取验证码" : "获取验证码(" + wait + ")"
                }}</el-button
              >
            </el-input>
          </el-tab-pane>
          <!-- 忘记密码 -->
          <el-tab-pane label="" name="third" disabled>
            <el-input
              ref="tPhone"
              placeholder="请输入手机号"
              v-model="forget.phone"
              class="input-with-select"
              clearable
              :maxlength="11"
            >
             <div slot="append">
                  <country-code-selector
                    :countryCode.sync="forget.areaCode"
                  ></country-code-selector>
                </div>
              <el-button slot="prepend" icon="el-icon-user"></el-button>
            </el-input>
            <el-input
              ref="tCode"
              placeholder="请输入验证码"
              v-model="forget.code"
              class="input-with-select m-t-10"
              clearable
              :maxlength="4"
            >
              <el-button
                slot="prepend"
                icon="el-icon-chat-dot-square"
              ></el-button>
              <el-button
                slot="append"
                type="success"
                @click="getCode"
                :disabled="wait != 60"
                >{{
                  wait == "60" ? "获取验证码" : "获取验证码(" + wait + ")"
                }}</el-button
              >
            </el-input>
            <el-input
              placeholder="请输入密码"
              v-model="forget.password"
              class="input-with-select m-t-10"
              type="password"
              clearable
            >
              <el-button slot="prepend" icon="el-icon-lock"></el-button>
            </el-input>
            <el-input
              placeholder="请再次输入密码"
              v-model="forget.againPassword"
              class="input-with-select m-t-10"
              type="password"
              clearable
            >
              <el-button slot="prepend" icon="el-icon-lock"></el-button>
            </el-input>
          </el-tab-pane>
          <!-- 注册账号 -->
          <el-tab-pane label="" name="fourth" disabled>
            <el-input
              ref="rPhone"
              placeholder="请输入手机号"
              v-model="reg.phone"
              class="input-with-select"
              clearable
              :maxlength="11"
            >
             <div slot="append">
                  <country-code-selector
                    :countryCode.sync="reg.areaCode"
                  ></country-code-selector>
                </div>
              <el-button slot="prepend" icon="el-icon-user"></el-button>
            </el-input>
            <el-input
              placeholder="请输入密码"
              v-model="reg.password"
              class="input-with-select m-t-10"
              type="password"
              clearable
            >
              <el-button slot="prepend" icon="el-icon-lock"></el-button>
            </el-input>
            <el-input
              placeholder="请再次输入密码"
              v-model="reg.againPassword"
              class="input-with-select m-t-10"
              type="password"
              clearable
            >
              <el-button slot="prepend" icon="el-icon-lock"></el-button>
            </el-input>
            <el-input
              ref="rCode"
              placeholder="请输入验证码"
              v-model="reg.code"
              class="input-with-select m-t-10"
              :maxlength="4"
              clearable
            >
              <el-button
                slot="prepend"
                icon="el-icon-chat-dot-square"
              ></el-button>
              <el-button
                slot="append"
                type="success"
                @click="getCode"
                :disabled="wait != 60"
                >{{
                  wait == "60" ? "获取验证码" : "获取验证码(" + wait + ")"
                }}</el-button
              >
            </el-input>
          </el-tab-pane>
        </el-tabs>
      </div>
      <el-button type="warning" class="w-82 m-t-20" @click="subLogin">{{
        subText()
      }}</el-button>
      <el-row class="p-l-40 p-r-40">
        <el-col :span="24">
          <!-- <el-button
            type="text"
            class="f-l-l xz-color-black"
            @click="swichTabs('third')"
            v-if="activeName != 'third' && activeName != 'fourth'"
            >忘记密码?</el-button
          > -->
          <el-button
            type="text"
            class="f-l-l xz-color-black"
            @click="swichTabs('second')"
            v-if="activeName == 'third' || activeName == 'fourth'"
            >返回登录</el-button
          >
          <el-button
            type="text"
            class="f-l-r xz-color-black"
            @click="swichTabs('fourth')"
            v-show="activeName != 'fourth'"
            >还没有账号,<span class="xz-color-red">去注册</span></el-button
          >
        </el-col>
      </el-row>
       <el-dialog
          width="25%"
          :visible.sync="innerVisible"
          append-to-body>
          <p class="text-c f-s-22">扫码添加老师微信,免费<span style="color:red">领取学习资料</span></p>
          <div class="text-c">
            <el-image :src="teacherWx" style="width:60%;border:1px solid #ccc"></el-image>
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button @click="innerVisible = false">关 闭</el-button>
            <!-- <el-button type="primary" @click="dialogVisible = false">确 定</el-button> -->
          </span>
        </el-dialog>
    </el-dialog>
  </div>
</template>

<script>
import countryCodeSelector from "vue-country-code-selector";
export default {
  components: {
    countryCodeSelector,
  },
  data() {
    return {
      teacherWx:require("@/assets/img/home/teacherWx.png"),
      activeName: "second",
      areaCode: 86,
      innerVisible:false,
      login: {
        phone: "",
        password: "",
        areaCode: 86,
      },
      code: {
        phone: "",
        code: "",
        areaCode: 86,
      },
      wait: 60,
      forget: {
        phone: "",
        code: "",
        password: "",
        againPassword: "",
        areaCode: 86,
      },
      reg: {
        phone: "",
        code: "",
        password: "",
        againPassword: "",
        areaCode: 86,
      },
      codeType: true,
    };
  },
  created() {
    let Bt = this.$route.query.Bt;
    if (this.$f.isNotNull(Bt) && Bt != this.$f.getLocal("Bt")) {
      this.$f.setLocal("Bt", Bt);
    }
  },
  methods: {
    subLogin() {
      let check = this.Check;
      if (this.activeName == "first") {
        if (this.login.areaCode == 86 && !this.firstCheck(check)) return false;
        let data = {
          loginname: this.login.phone,
          pwd: this.login.password,
          areaCode:this.login.areaCode
        };
        this.allLogin(data, 1);
      } else if (this.activeName == "second") {
        if (this.code.areaCode == 86 && !this.secondCheck(check)) return false;
        let data = {
          loginname: this.code.phone,
          code: this.code.code,
          areaCode:this.code.areaCode
        };
        this.allLogin(data, 2);
      } else if (this.activeName == "third") {
        if (this.forget.areaCode == 86 && !this.thirdCheck(check)) return false;
        let data = {
          loginname: this.forget.phone,
          pwd: this.forget.password,
          code: this.forget.code,
          areaCode:this.forget.areaCode
        };
        this.allLogin(data, 3);
      } else if (this.activeName == "fourth") {
        if (this.reg.areaCode == 86 && !this.fourthCheck(check)) return false;
        let data = {
          loginname: this.reg.phone,
          pwd: this.reg.password,
          code: this.reg.code,
          areaCode:this.reg.areaCode
        };
        this.allLogin(data, 4);
      }
    },
    allLogin(data, type) {
      let _that = this;
      if (type == "1") {
        this.Api.Login.login(data).then((res) => {
          console.log(res);
          if (res.status == 200) {
            this.loginSuc(res);
          } else if (res.status == 5021) {
            this.$confirm("该账号已在别处登录,请通过手机验证码登录", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            })
              .then(() => {
                this.activeName = "second";
                this.code.phone = data.loginname;
              })
              .catch(() => {});
          } else if (res.message == "账号不存在") {
            this.$confirm("当前账号暂未注册,是否去注册", "提示", {
              confirmButtonText: "注册",
              cancelButtonText: "取消",
              type: "warning",
            })
              .then(() => {
                this.activeName = "fourth";
                this.reg.phone = data.loginname;
              })
              .catch(() => {});
          }
        });
      } else if (type == "2") {
        this.Api.Login.codeLogin(data).then((res) => {
          if (res.status == 200) {
            this.loginSuc(res);
          } else {
            this.$message.error(res.message);
          }
        });
      } else if (type == "3") {
        this.Api.Login.editPwd(data).then((res) => {
          if (res.status == 200) {
            this.$confirm("密码修改成功,立即去登录", "提示", {
              confirmButtonText: "登录",
              cancelButtonText: "取消",
              type: "success",
            })
              .then(() => {
                let forget = {
                  loginname: data.loginname,
                  pwd: data.pwd,
                };
                _that.allLogin(forget, "1");
              })
              .catch(() => {});
          } else {
            this.$message.error("修改密码失败");
          }
        });
      } else if (type == "4") {
        this.Api.Login.register(data).then((res) => {
          if (res.status == 200) {
            this.$confirm("恭喜您,注册成功!是否立即登录", "提示", {
              confirmButtonText: "登录",
              cancelButtonText: "取消",
              type: "success",
            })
              .then(() => {
                let sub = {
                  loginname: data.loginname,
                  pwd: data.pwd,
                };
                _that.allLogin(sub, "1");
              })
              .catch(() => {});
            this.innerVisible = true
          } else {
            this.$message.error("注册失败,请联系学习管家或助教");
          }
        });
      }
    },
    loginSuc(res) {
      this.$store.commit("overall/editUser", res.data);
      this.$f.setLocal("user", res.data);
      this.$f.goLogon(true);
      location.reload();
    },
    // 账号密码登录验证
    firstCheck(check) {
      if (!check.phone(this.login.phone)) {
        this.$refs.fPhone.focus();
        return false;
      }
      if (!check.empty(this.login.password, "密码不能为空")) {
        this.$refs.fPassword.focus();
        return false;
      }
      return true;
    },
    // 验证码登录验证
    secondCheck(check) {
      if (!check.phone(this.code.phone)) {
        this.$refs.sPhone.focus();
        return false;
      }
      if (!check.code(this.code.code)) {
        this.$refs.sCode.focus();
        return false;
      }
      return true;
    },
    // 忘记密码验证
    thirdCheck(check) {
      if (!check.phone(this.forget.phone)) {
        this.$refs.tPhone.focus();
        return false;
      }
      if (!check.code(this.forget.code)) {
        this.$refs.tCode.focus();
        return false;
      }
      if (
        !check.ident(
          this.forget.password,
          this.forget.againPassword,
          "两次输入的密码不一致"
        )
      )
        return false;
      return true;
    },
    // 注册账号验证
    fourthCheck(check) {
      if (!check.phone(this.reg.phone)) {
        this.$refs.rPhone.focus();
        return false;
      }
      if (
        !check.ident(
          this.reg.password,
          this.reg.againPassword,
          "两次输入的密码不一致"
        )
      )
        return false;
      if (!check.code(this.reg.code)) {
        this.$refs.rCode.focus();
        return false;
      }
      return true;
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    swichTabs(type) {
      this.activeName = type;
    },
    // 获取验证码
    getCode() {
      let wait = this.wait;
      let _that = this;
      let t = "";
      if (wait == 0) {
        this.codeType = true;
        _that.wait = 60;
        clearTimeout(t);
        return false;
      }else if(wait == 60){
        _that.wait--;
        t = setTimeout(() => {
          this.getCode();
        }, 1000);
      }else {
        this.codeType = false;
        _that.wait--;
        t = setTimeout(() => {
          this.getCode();
        }, 1000);
        return false;
      }

      let check = this.Check;
      if (this.code.areaCode == 86 && this.activeName == "second") {
        if (!check.phone(this.code.phone)) {
          this.$refs.sPhone.focus();
          return false;
        }
      } else if (this.forget.areaCode == 86 && this.activeName == "third") {
        if (!check.phone(this.forget.phone)) {
          this.$refs.tPhone.focus();
          return false;
        }
      } else if (this.reg.areaCode == 86 && this.activeName == "fourth") {
        if (!check.phone(this.reg.phone)) {
          this.$refs.rPhone.focus();
          return false;
        }
      }
      if (this.codeType) {
        if (this.activeName == "second") {
          this.subCode(this.code.phone,this.code.areaCode);
        } else if (this.activeName == "third") {
          this.subCode(this.forget.phone,this.forget.areaCode);
        } else if (this.activeName == "fourth") {
          this.subCode(this.reg.phone,this.reg.areaCode);
        }
      }
    },
    subCode(loginname,areaCode) {
      let data = {
        loginname,
        areaCode
      };
      this.Api.Login.getCode(data).then((res) => {
        if (res.status == "200") {
          this.$message.success("验证码发送成功");
        } else {
          this.$message.error("验证码发送失败,请重试");
        }
      });
    },
    // 关闭登录对话框
    handleClose() {
      let path = this.$route.path;
      if (path == "/live/getLiveBroadcast" && !this.$f.uid()) {
        this.$message.error("请先登录后进入直播间");
        return false;
      } else if (path == "/personal/coponSrc" && !this.$f.uid()) {
        this.$message.error("登录后才可以领取优惠券");
        return false;
      }
      this.$f.goLogon(true);
    },
    loginText() {
      switch (this.activeName) {
        case "first":
          return "账号密码登录";
        case "second":
          return "验证码登录";
        case "third":
          return "忘记密码";
        case "fourth":
          return "注册";
      }
    },
    subText() {
      // 按钮显示的文字
      switch (this.activeName) {
        case "third":
          return "确定";
        case "fourth":
          return "注册";
        default:
          return "登录";
      }
    },
  },
  computed: {
    loginType: {
      get() {
        return this.$store.state.overall.loginType;
      },
      set(val) {
        console.log(val);
      },
    },
    loginActiveName() {
      return this.$store.state.overall.loginActiveName;
    },
  },
  watch: {
    loginActiveName: {
      immediate: true,
      deep: true,
      handler(val) {
        if (this.activeName == val) return false;
        this.activeName = val;
      },
    },
  },
};
</script>

<style scoped>
.el-tabs >>> .area-codeNum {
  padding-top: 0;
  padding-bottom: 0;
}
.el-tabs >>> .el-tabs__content {
  overflow: unset;
}
.input-with-select >>> .flag-list-box {
  background: white;
}
.el-dialog__wrapper >>> .el-dialog {
  min-width: 482px;
}
.tab >>> .el-tabs__header {
  display: none;
}
.activeClick {
  color: #f0864a;
  border-bottom: 2px solid #f0864a;
  padding-bottom: 3px;
}
.el-tab-pane >>> .el-input-group__prepend {
  background: white;
}
.el-tab-pane >>> .el-input-group__prepend {
  border-right: 0;
}
</style>
