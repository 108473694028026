<template>
  <div>
    <el-dialog
      title="签署协议"
      :visible.sync="dialogVisible"
      width="40%"
      :before-close="handleClose"
    >
      <div class="text-l">
        <p>{{ textOne }}</p>
        <el-input
          v-for="(item, i) in signInfoList"
          :key="i"
          v-model="item.info"
          :placeholder="item.agreementsign"
          style="width: 48%; margin-left: 1%"
          class="m-t-10"
        ></el-input>
        <p>重要提示:</p>
        <p>a.用户报名小筑《{{
            name
          }}》必须签署协议,否则不能享受后续服务;
        </p>
        <p>b.用户在《{{
            name
          }}》协议中所塡写信息必须真实,且与用户的注册信息一致
        </p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancle">返 回</el-button>
        <el-button type="primary" @click="sing">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    cid: String,
    title: {
      type: String,
      default: "课前须知",
    },
  },
  data() {
    return {
      dialogVisible: false,
      info: {
        name: "",
        id: "",
      },
      name:"",
      signInfoList: [],
      textOne:
        "我们非常重视您的隐私和个人信息保护,本协议签名仅限用于课程协议用途,不会泄露您的信息",
    };
  },
  methods: {
    sing() {
      if (this.title == "课前须知") {
        for (const key in this.signInfoList) {
          if (this.signInfoList[key].info == "") {
            this.$message.error(
              "请填写" + this.signInfoList[key].agreementsign + "信息"
            );
            return false;
          }
        }
        let data = {
          uid: this.$f.uid(),
          cid: this.cid,
          userSignAgreementInfos: this.signInfoList,
        };
        this.Api.Agreement.updUserSignAgreementInfo(data).then((res) => {
          if (res.status == 200) {
            this.handleClose();
            let { href } = this.$router.resolve({
              path: "/player",
              query: { cid: this.cid },
            });
            window.open(href, "_blank");
          } else {
            this.$message.error(res.message);
          }
        });
      } else {
        this.$emit("getAgreementLsit");
        this.handleClose();
      }
    },
    handleClose() {
      this.dialogVisible = false;
    },
    // 取消签署协议
    cancle() {
      this.dialogVisible = false;
      this.$parent.dialogVisible = true;
    },
  },
};
</script>

<style scoped>
</style>