export default {
  namespaced: true,
  state: {
    loginType: false,   // 用户是否登录
    loginActiveName:"second",  // 控制打开登录注册对话框默认显示什么
    user:{},
    token:"",
    msgType:false,  // 控制首页消息中心抽屉显示
    skipTrailerInfo:{
      vid:0,
      cid:0,
      gid:0,
    },
    videoNum:0
  },
  mutations: {
    // 该自动播放下一个视频了
    skipTrailerInfo(state,val){
      state.skipTrailerInfo.vid = val.vid
      state.skipTrailerInfo.cid = val.cid
      state.skipTrailerInfo.gid = val.gid
      state.videoNum += 1
    },
    // 修改首页消息中心抽屉状态
    editMsgType(state,val){
      state.msgType = val
    },
    editToken(state,val){
      state.token = val
    },
    editUser(state,val){
      state.user = val
    },
    editLoginType(state,type){
      state.loginType = type
    },
    editLoginActiveName(state,val){
      state.loginActiveName = val
    },
  },
  actions: {},
};
