export default {
  namespaced: true,
  state: {
    tabActive: "1",
    calssSearchType:'2' ,  // 1 input筛选  2 专业类型筛选
    searchTextChange:'',
    homeActive:'',
  },
  mutations: {
    editTabActive(state, val) {
      state.tabActive = val
    },
    editClassSearchType(state,val) {
      state.calssSearchType = val
    },
    editSearchTextChange(state,val) {
      state.searchTextChange = val
    },
    editHomeActive(state,val) {
      state.homeActive = val
    }
  },
  actions: {},
};
