<template>
  <div>
    <el-row v-if="ExcellentClassList.length > 0">
      <el-col
        :span="6"
        class="bg-white exItem"
        @click.native="goDetial(item)"
        v-for="(item, index) in ExcellentClassList"
        :key="index"
        style="width: 23.4%; height: 301px"
        :style="Excell(item, index)"
      >
        <!-- <el-tag type="danger" class="f-l-l m-t-5 exceItemTag" color="#CE3112"
          >新班上线</el-tag
        > -->
        <div class="classType">
          <p class="title text-l f-s-18" style="margin-top:0;margin-bottom:10px">
            <!-- <el-tag effect="dark" type="info" class="m-r-5" style="height:18px;line-height:18px;padding-left:4px;padding-right:4px" color="#636161">就业</el-tag
            > -->
            {{ item.courseTitle }}
          </p>
          <p class="time text-l yichuOne" style="margin:0">
            {{ item.countStarttime }}至{{ item.countStopTime }} |
            {{ item.ccount }}课时
          </p>
          <!-- 老师头像循环列表 -->
          <el-row>
            <div>
              <div
                class="teacherPic"
                v-for="(t, index) in item.tlist"
                :key="index"
              >
                <el-avatar :src="t.turl"></el-avatar>
                <p class="teacherName f-s-14">{{ t.tname }}</p>
              </div>
            </div>
          </el-row>
          <!-- 价格 -->
          <el-row class="m-t-24 b-t-1 p-t-15 color-grey">
            <div class="f-s-14">
              <div class="f-l-l">限购人数:{{ item.courseMax }}人</div>
              <div class="f-l-r">
                <del>¥{{ item.courseOriginal }}</del>
              </div>
            </div>
          </el-row>
          <!-- 预约 -->
          <div class="f-s-14 m-t-6">
            <div class="f-l-l" style="height: 40px; line-height: 40px">
              {{ item.courseXnum }}人已购买
            </div>
            <div class="f-l-r color-or">
              <span>￥</span>
              <span class="f-s-26">{{ item.coursePrice }}</span>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>

    <el-row class="text-l" v-else>
      <el-col style="width: 23.4%; height: 301px;padding:20px" v-for="(item, index) in skeletonNum" :key="index"
        :style="Excell(item, index)" class=" exItem">
        <el-skeleton style="height: 301px" :loading="true" animated :rows="8">
          <el-skeleton-item style="width: 100%; height: 301px" />
        </el-skeleton>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  props: {
    ExcellentClassList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    type: String,
  },
  data() {
    return {
      url: "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
      skeletonNum: [
        { id: 1 },
        { id: 2 },
        { id: 3 },
        { id: 4 },
        { id: 5 },
        { id: 6 },
      ],
    };
  },
  methods: {
    // 跳转到详情界面
    goDetial(item) {
      let { href } = this.$router.resolve({
        path: "/course/toOneCenter",
        query: { cid: item.cid },
      });
      window.open(href, "_blank");
    },
    // 样式
    Excell(item, index) {
      if (this.type == "home") {
        // 在首页展示的列表样式
        if (index == 0) {
          return { marginLeft: "7px" };
        } else if(index == 1){
          return { marginLeft: "26px", marginTop: "0" };
        } else if (index > 1 && index % 2 == 0 && index %4 !=0) {
          return { marginLeft: "0", marginTop: "1.875rem" };
        } else if (index > 1 && index % 2 != 0 || index %4 ==0) {
          return { marginLeft: "1.6rem", marginTop: "1.875rem" };
        }
      } else if (this.type == "curr") {
        // 在课程咨询展示的列表样式
        if (index < 3) {
          return { marginRight: "1.6rem", marginTop: "1.875rem" };
        } else if ((index + 1) % 4 == 0) {
          return { marginTop: "1.875rem" };
        } else if (index == 0) {
          return { marginRight: "1.6rem", marginTop: "1.875rem" };
        } else {
          return { marginRight: "1.6rem", marginTop: "1.875rem" };
        }
      }
      if (this.type == "recomm") {
        return { width: "100%" };
      } else {
        return { width: "23.5%" };
      }
    },
  },
};
</script>

<style scoped>
.exceItemTag {
  color: white !important;
  height: 20px;
  line-height: 20px;
  padding-left: 1.5rem;
  border-radius: 0px 10px 10px 0px;
}
.classType {
  padding: 30px;
  /* box-shadow: 0px 0px 4px 1px rgb(0 0 0 / 5%); */
  /* margin-right:30px */
}
/* .classType:hover {
  
} */
.exItem {
  transition: all .3s;
  border-radius: 10px;
}
.exItem:hover {
  top: -10px;
  /* box-shadow: 0px 0px 8px 2px rgb(0 0 0 / 20%); */
  
box-shadow: 0px 8px 17px 4px rgba(0,0,0,0.1);
  cursor: pointer;
  transform: translateY(-6px);
  -moz-transform: translateY(-6px);
  -o-transform: translateY(-6px);
  -webkit-transform: translateY(-6px);
}
.title {
  height: 46px;
  margin-bottom: 1rem;
  /* font-size:18px; */
  line-height: 24px;
}
.time {
  font-size:13px;
  color: grey;
  height: 36px;
  margin-bottom: 0.625rem;
}
.teacherPic {
  width: 46px;
  height: 46px;
  float: left;
  display: block;
  margin-right: 1.25rem;
  margin-bottom: 1rem;
}
.teacherName {
  /* font-size:14px; */
  line-height: 20px;
  color: #666;
  margin: 6px 0;
}
</style>
