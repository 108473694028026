<template>
  <div style="z-index:21;overflow:hidden;">
    <el-row
      class="p-t-10"
      :style="{ background: 'url(' + footBg + ') no-repeat right 0px' }"
    >
    <!-- :xl="{span:16,offset:4}" :lg="{span:16,offset:0}" :md="{span:16,offset:0}" -->
      <div class="xz-b">
        <el-row>
          <el-col :span="6">
            <h2 class="text-l f-s-16">了解小筑</h2>
            <p>公司名称：北京小筑未来教育科技有限公司</p>
            <p>公司地址：<span>北京市海淀区知春路7号</span></p>
            <p style="text-indent:65px">致真大厦D座4层D4221</p>
            <p><span style="letter-spacing:12px">邮编</span>：100191</p>
          </el-col>

          <el-col :span="6">
            <h2 class="text-l f-s-16">联系我们</h2>
            <p>客服电话：010-57130711</p>
            <p><a href="https://space.bilibili.com/387445073?from=search&seid=5322041946224783986">B站：小筑教育BIM研究院</a></p>
            <p>微信公众号：小筑BIM一起学</p>
          </el-col>

          <el-col :span="6">
            <h2 class="text-l f-s-16">战略合作</h2>
            <p>中国建设教育协会</p>
            <p>欧特克软件有限公司</p>
            <p>广联达科技股份有限公司</p>
            <p>中交一公局集团有限公司</p>
          </el-col>
          <el-col :span="6">
            <h2 class="text-l f-s-16">关注我们</h2>
            <el-row>
              <el-col :span="12">
                <el-image :src="WxCx"></el-image>
                <p class="text-c">官方微信</p>
              </el-col>
              <el-col :span="12">
                <el-image :src="downApp" style="width:108px"></el-image>
                <p class="text-c">下载app</p>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </div>
      <div
        :span="24"
        style="background:black;height:60px;line-height:60px;text-align:center"
      >
      <div class="xz-b text-c" style="text-align: center">
        Copyright ©2019北京小筑未来教育科技有限公司版权所有 |
        <!-- <div></div> -->
        <el-image
          :src="chinese"
          style="width:16px;height:16px;margin-left:5px;margin-right:5px"
        ></el-image>
        <a
          href="https://beian.miit.gov.cn/#/Integrated/index"
          style="color:#666;text-decoration:none"
          >京ICP备19030800号-1</a
        >
        |
        <el-image
          :src="bottomIcon"
          style="width:16px;height:16px;margin-left:5px;margin-right:5px"
        ></el-image>
        北京网安备 |
        <span>实名认证</span>
        <span>
          <a
            style="width:83px;height:30px;display:inline-block;
    vertical-align: top;"
            href="https://xyt.xinchacha.com/pcinfo?sn=507061170792960000&certType=6"
          >
            <el-image
              :src="icon3"
              style="width:83px;height:30px;margin-left:5px;margin-right:5px;margin-top:15px;position:absolute"
            ></el-image>
          </a>
        </span>
        <span>
          <a href="https://xyt.xcc.cn/getpcInfo?sn=1476487800864215040&language=CN&certType=8&url=*.xzjy365.com" target="_blank" style="position: relative;display: inline-block;height: 38px;
    vertical-align: middle;
    margin-left: 10px;">
          <div style="width:100%;height:100%;position: absolute;top: 0;left: 0;"></div><embed src="https://program.xinchacha.com/web/1476487800864215040=*.xzjy365.com.svg" width="103" height="38" type="image/svg+xml" pluginspage="//www.adobe.com/svg/viewer/install/"/>
          </a>
        </span>
        </div>
      </div>
    </el-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      GfWx: require("@/assets/img/home/Wechat.png"),
      WxCx: require("@/assets/img/home/WechatIMG2.png"),
      footBg: require("@/assets/img/home/foot.png"),
      chinese: require("@/assets/img/home/chinese.png"),
      bottomIcon: require("@/assets/img/home/bottom-Icon-2.png"),
      icon3: require("@/assets/img/home/icon3.png"),
      downApp: require("@/assets/img/home/downApp.png"),

    };
  },
  methods:{
  }
};
</script>


<style scoped>
* {
  color: white;
  font-size: 13px;
  text-align: left;
}
a{
text-decoration:none;
}
</style>
