// import { turn } from "core-js/core/array";
import { Message } from "element-ui";
export default {
  // 判空验证
  empty(context,text = "请填写完成") {
    if(context == undefined || context == 'undefined' || context == '' || context == null){
      Message.error(text)
      return false
    }
    return true
  },
  // 验证长度
  lengthC(context,num,text){
    if(context.length < num){
      Message.error(text)
      return false
    }
    return true
  },
  // 手机号校验
  phone(context,text = "手机号格式不正确"){
    if(!this.empty(context,"手机号不能为空")) return false
    if(context.length != 11){
      Message.error(text)
      return false
    }
    // let myreg = /^[1][2,3,4,5,6,7,8,9][0-9]{9}$/;
    // if (!myreg.test(context)) {
    //   Message.error(text)
    //   return false
    // }
    return true

  },
  // 验证码校验
  code(context){
    if(!this.empty(context,"验证码不能为空")) return false
    if(!this.lengthC(context,4,"验证码长度不能小于4位")) return false
    return true
  },
  // 判断两个值知否一样
  ident(one,two,text){
    if(JSON.stringify(one) != JSON.stringify(two)){
      Message.error(text)
      return false
    }
    return true
  }
};
