import axios from "@/plugins/axios";

export default {
  getLiveInfo(data) {  // 获取直播信息
    return axios.post("live/getLiveByLiveId", data)
  },
  getLiveUri(data){  // 获取直播链接
    return axios.post("live/getLiveBroadcastByLiveId",data)
  }
};
