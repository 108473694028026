<template>
  <div>
    <vue-qr :size="200" :text="alpayUrl" :logoSrc="icon" v-if="alpayUrl != ''"> </vue-qr>
  </div>
</template>

<script>
import VueQr from "vue-qr";
export default {
  props:{
    icon:String
  },
  data() {
    return {
      form: "",
      alpayUrl:""
    };
  },
  components: {
    VueQr,
  },
};
</script>

<style scoped>

</style>