import axios from "@/plugins/axios";

export default {
  getOrderList(data) {// 获取订单的信息   1. 未支付   2. 已支付
    return axios.get("order/getOrder",data);
  },
  getOrderDetial(data){  // 获取订单详情
    return axios.get("order/getOrderDetial",data)
  },
  getClassList(data){  // 获取课程列表
    return axios.get("class/classList",data)
  },
  getClassDetial(data){
    return axios.get("class/toLookOneVideoPag",data)
  },
  getVideoListByGid(data) {
    return axios.get("curric/getVideoListByGid",data)
  },
  // 优惠券
  getCoupon(data){
    return axios.post("coupon/GetUserCouponList",data)
  },
  StorageTime(data){  // 存储用户开始学习时间
    return axios.post("video/StorageTime",data)
  },
  addUserVideoStatusAndLog(data){
    return axios.post("video/addUserVideoStatusAndLog",data)
  },
};
