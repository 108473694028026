import axios from "@/plugins/axios";


export default {
  getPicUrl(data){  // 传入数组id  获取图片的url地址
     return axios.post('getpactureById',data)
  },
  trainInfo(data){
      return axios.post('/EnterpriseTrain/addTrainInfo',data)
  }
}