<template>
  <div class="text-l">
    <el-row>
      <el-col :span="6" v-for="(item, i) in liveList" :key="i" v-show="i<=3">
        <div style="overflow: hidden; white-space: nowrap">
          <i
            class="el-icon-video-play f-s-22 f-l-l m-r-5"
            v-if="item.livestate != 2"
          ></i>
          <i
            v-else
            class="el-icon-s-data f-s-16 f-l-l b-r-13 m-r-5"
            style="background: #f08041; color: white; padding: 5px"
          ></i>
          <span style="font-weight: 500; color: #333333">
            {{
              item.liveDate
            }}-------------------------------------------------------
          </span>
        </div>
        <div class="m-t-20 p-r-10" v-if="item.livestate == 1">
          <span class="f-s-16 m-l-5 color-grey"
            >{{ item.livestartDate }} - {{ item.liveendDate }}</span
          >
          <el-button
            size="mini"
            class="f-l-r bookingLive"
            :class="item.bookStare == 2 ? 'bookingLive' : ''"
            @click="bookLive(item)"
            :disabled="item.bookStare == 1"
            >{{ item.bookStare == 2 ? "预约直播" : "已预约" }}</el-button
          >
        </div>
        <div class="m-t-20 p-r-10" v-if="item.livestate == 2">
          <span class="f-s-16 m-l-5" style="color: #f08041">正在直播</span>
          <el-button
            size="mini"
            class="f-l-r goLive"
            @click="goLive(item)"
            >进入直播</el-button
          >
        </div>
        <div class="bg-white liveItem m-t-20" style="width: 83%">
          <el-row>
            <el-col :span="10">
              <el-image :src="item.tHalfPhoto"></el-image>
            </el-col>
            <el-col :offset="1" :span="13">
              <div style="min-height:80px">
                <el-tag
                  effect="dark"
                  type="info"
                  class="m-r-5 titleTag"
                  color="#636161"
                  v-if="item.itname != null"
                  >{{item.itname}}</el-tag
                >
                {{ item.subject }}
              </div>

              <p class="f-s-14 m-t-15 teacherText" style="margin-top: 15px">
                授课老师:{{ item.teachers }}
              </p>
              <p class="f-s-14 teacherText" style="margin-top: 4px">
                预约人数:{{ item.realBookedNum }}
              </p>
            </el-col>
          </el-row>
        </div>
      </el-col>
    </el-row>
    <el-dialog
      title="预约成功"
      :visible.sync="dialogVisible"
      width="20%"
      :before-close="handleClose"
      class="text-c"
    >
      <p style="margin: 0">关注微信服务号，开启直播通知</p>
      <el-image src="https://webxiaozhu.xzjy365.com/images/qrcode_for_gh_028263ec57bf_430.jpg" class="m-t-10"></el-image>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false" size="mini"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    liveList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      dialogVisible: false,
      GfWx: require("@/assets/img/home/Wechat.png"),
      WxCx: require("@/assets/img/home/WechatIMG2.png"),
    };
  },
  methods: {
    goLive(item){
      let { href } = this.$router.resolve({
        path: "/live/getLiveBroadcast",
        query: { LiveId: item.id },
      });
      window.open(href, "_blank");
    },
    handleClose() {
      this.dialogVisible = false;
    },
    bookLive(item) {
      // 预约直播
      if (!this.$f.uid()) {
        this.$f.goLogon();
        return false;
      }
      let data = {
        uid: this.$f.uid(),
        LiveId: item.id,
        belong: this.$f.getBelong(),
      };
      this.Api.Login.bookLiveBroadcast(data).then((res) => {
        if (res.status == 200) {
          item.bookStare = 1;
          item.realBookedNum += 1;
          this.$message.success("预约成功");
          this.dialogVisible = true;
        }
      });
      this.dialogVisible = true;
    },
  },
};
</script>

<style scoped>
.el-dialog__wrapper >>> .el-dialog {
  min-width: 382px;
}
.headBorder {
  width: 92%;
  height: 7px;
  background: #f9fafb;
  margin-top: 8px;
}
.liveItem {
  padding: 20px;
  box-shadow: 0px 4px 27px 4px rgba(0, 0, 0, 0.05);
  transition: all .3s;

}
.liveItem:hover{
    top:-10px;
 box-shadow: 0px 8px 17px 4px rgba(0,0,0,0.1);
  cursor: pointer;
  transform: translateY(-6px);
     -moz-transform: translateY(-6px);
     -o-transform: translateY(-6px);
     -webkit-transform: translateY(-6px);
}
.titleTag {
  height: 18px;
  line-height: 18px;
  padding-left: 4px;
  padding-right: 4px;
}
.teacherText {
  color: #636161;
  font-weight: 400;
  margin: 0;
}
.bookingLive {
  background: #ffeee5;
  border-radius: 18px;
  color: #f08041;
  border: 0;
}
.goLive {
  border: 0;
  border-radius: 18px;

  color: white;
  background: linear-gradient(90deg, #ff8a18 0%, #f7ae6d 0%, #f08041 100%);
}
</style>