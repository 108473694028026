import Vue from 'vue'
import Vuex from 'vuex'
import overall from './item/overall'
import learn from './item/learn'
import ws from './item/ws'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    overall,
    learn,
    ws
  }
})
