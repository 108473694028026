<template>
  <div style="display:flex" class="m-b-10">
    <el-button
      type="text"
      style="flex:1;padding-top:5px;padding-bottom:5px"
      class="f-s-20 headType"
      v-for="(item, i) in classifyList"
      v-show="classifyList.length > 1"
      :key="i"
      @click="ifyChange(item)"
      >{{ item.itname }}</el-button
    >
      <!-- :class="{'ifyActivf':item.itid == active}" -->

  </div>
</template>

<script>
export default {
  created() {
    this.getClassifyList();
  },
  data() {
    return {
      classifyList: [],
      active:''
    };
  },
  methods: {
    getClassifyList() {
      this.Api.Login.itemFirstLevel().then((res) => {
        if (res.retuCode == 200) {
          this.classifyList = res.data;
        }
      });
    },
    ifyChange(item){
      this.$store.commit("learn/editHomeActive",item.itid)
      this.$router.push('/curriculum')
      // this.active = item.itid

    }
  },
};
</script>

<style scoped>
.ifyActivf {
  background: white;
  border-radius: 20px;
}
.headType {
  color: black;
}
.headType:hover {
  background: rgb(240, 128, 65);
  border-radius: 20px;
  color: white;
}
</style>