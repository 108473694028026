// import Vue from "vue";
import store from "../store/index";
export default {
  // 打开或关闭消息中心抽屉
  openOrcloseMsg() {
    // let type = store.state.overall.msgType
    // store.commit("overall/editMsgType", !type)
  },
  // 弹出登录注册对话框
  goLogon(type = false) {
    if (type) {
      store.commit("overall/editLoginType", false);
    } else {
      store.commit("overall/editLoginType", true);
      if (store.state.overall.loginActiveName != "second")
        store.commit("overall/editLoginActiveName", "second");
    }
  },
  // 本地存储数据
  setLocal(name, data) {
    localStorage.setItem(name, JSON.stringify(data));
  },
  // 获取本地的存储数据
  getLocal(name) {
    return JSON.parse(localStorage.getItem(name));
  },
  // 存储settiong数据
  setSettiong(name,data){
    sessionStorage.setItem(name,JSON.stringify(data))
  },
  // 获取settiong存储数据
  getSession(name) {
    return JSON.parse(sessionStorage.getItem(name))
  },
  // 清空本地存储
  removeLocal(arr) {
    if (typeof arr == "string") {  // 如果传入的是单个字符串
      localStorage.removeItem(arr);
      return;
    } else if (Array.isArray(arr)) {  // 传入的是数组
      arr.forEach((a) => {
        localStorage.removeItem(a);
      });
    }
  },
  // 获取uid
  uid(item) {
    let user = JSON.parse(localStorage.getItem("user"))
    if (user == undefined || user == null || user == "") {
      if (item != 0) store.commit("overall/editLoginType", true);
      if (store.state.overall.loginActiveName != "second")
        store.commit("overall/editLoginActiveName", "second");
      return false
    }
    return user.uid
  },
  phone(){
    let user = JSON.parse(localStorage.getItem("user"))
    if (user == undefined || user == null || user == "") {
      return null
    }
    return user.loginname
  },
  online() {
    window.open("https://wpa1.qq.com/URW2yFS6?_type=wpa&qidian=true")
  },
  // 判空验证
  isNotNull(val) {
    if ("" == val || null == val || undefined == val || "null" == val || JSON.stringify(val) == "{}" || val.length == 0) {
      return false
    }
    return true
  },
  // 获取对应图片id的链接地址
  getUrl(id, arr) {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].id == id) {
        return arr[i].url;
      }
    }
  },
  //    判断移动端还是PC端        true  移动端   false  PC端
  _isMobile() {
    let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
    return flag;
  },
  // 获取本地的belong归属信息
  getBelong(){
    let belong = JSON.parse(localStorage.getItem('belong'))
    if(belong != undefined && belong != '' && belong != null){
      return belong
    }
    return null
  },
  educationlist(){
    let lsit = [
      {id:"0",name:"高中"},
      {id:"1",name:"中专"},
      {id:"2",name:"大专"},
      {id:"3",name:"本科"},
      {id:"4",name:"研究生"},
      {id:"5",name:"博士及博士后"},
    ]
    return lsit
  },
  formatIDcard (value) {
    if (!value) return '';
    let str = value;
    str = str.toString().replace(/^(.{6})(?:\w+)(.{4})$/ , '$1********$2')
    return str;
  }
};
