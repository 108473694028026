import axios from "@/plugins/axios";


export default {
  generateOrder(data) {  // 下单
    return axios.post("order/generateOrder",data);
  },
  getCoupon(data){  // 获取优惠券
    return axios.post("Coupon/GetUserCouponListByCid",data)
  },
  Pay(data){ // 获取支付链接  
    return axios.post("order/ordertoPay",data)
  },
  payResult(data){  // 支付回调 查询是否支付成功
    return axios.get("order/getOrderDetial",data)
  }
}