import Login from "./item/login"
import Curr from "./item/curriculum"
import Data from "./item/dataDown"
import Consult from "./item/consulting"
import Center from "./item/center"
import Pay from './item/pay';  // 支付接口
import Coupon from './item/coupon';  // 支付接口
import Live from './item/live';
import Prise from './item/prise';
import Agreement from "./item/agreement"
import Exam from './item/exam'
import User from './item/user'

export default {
  Login, Curr, Data, Consult, Center, Pay, Coupon, Live, Prise, Agreement, Exam, User
};
