import Vue from "vue";
import Header from '@/components/public/header'
import Footer from '@/components/public/footer'
import Login from '@/views/overall/login'
import Suspension from '@/views/suspension'
import Msg from '@/components/public/Msg/msg';
import Pay from '@/views/pay/pay';
import wxPay from '@/components/pay/wxPay';
import AliPay from '@/components/pay/aliPay';
import Btn from '@/components/curriculum/item/btn';
import ClassTitle from "@/components/public/classTitle"
import VDistpicker from 'v-distpicker';//引入v-distpicker

Vue.component("Header",Header)
Vue.component("Footer",Footer)
Vue.component("Login",Login)
Vue.component("Suspension",Suspension)
Vue.component("Msg",Msg)
Vue.component("Pay",Pay)
Vue.component("WxPay",wxPay)
Vue.component("AliPay",AliPay)
Vue.component("Btn",Btn)
Vue.component("ClassTitle",ClassTitle)
Vue.component('v-distpicker',VDistpicker);//全局注册v-distpicker