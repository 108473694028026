import axios from "@/plugins/axios";

export default {
  getCoupon(data) {  // 获取优惠券
    return axios.get("coupon/GetCouponById", data)
  },
  receiveCou(data) {  // 领取优惠券
    return axios.get("coupon/addCouponByIdToUser", data)
  }
};
