<template>
  <el-button type="success" class="f-l-r m-r-10" id="details2"
    >在线咨询</el-button
  >
</template>

<script>
export default {
    data(){
        return {

        }
    }
}
</script>